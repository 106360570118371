import { CSSProperties, FunctionComponent, ReactElement, ReactNode } from 'react';
import Modal from 'react-modal';

import Close from './images/Close.svg'

import classes from './ModalBase.module.scss'

interface ModalBaseProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => boolean | void;
    title?: string | ReactElement;
    children?: ReactNode;
    style?: CSSProperties;
    className?: string;
}

const ModalBase: FunctionComponent<ModalBaseProps> = ({ isOpen, setIsOpen, title, children, style, className}) => {
    return (<Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        className={classes.ModalBase + (' ' + className ?? '')}
        overlayClassName={classes.Overlay}
        style={{content: style}}
        ariaHideApp={false}
    >
        <header>
            <h1>{title}</h1>
            <button onClick={()=>setIsOpen(false)}>
                <img src={Close} alt='close icon'/>
            </button>
        </header>
        <main>
            {children}
        </main>

    </Modal>);
}

export default ModalBase;