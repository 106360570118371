import { FunctionComponent, useState} from "react";
import { useNavigate } from "react-router-dom";
import ButtonLight from "../../Common/ButtonLight/ButtonLight";
import classes from './Header.module.scss';
import Logo from './images/Logo.svg'
import { useWeb3React } from '@web3-react/core'
import { injected } from '../../../connector'
import { useSelector } from 'react-redux';
import Person from './images/person.svg';
import AccountDialog from './accountDialog/AccountDialog'



interface HeaderProps {
    
}
 
const Header: FunctionComponent<HeaderProps> = () => {
    const { activate, deactivate, account } = useWeb3React();
    const balance = useSelector((state: any) => state.userWallet.balance);
    const decimals = useSelector((state: any) => state.userWallet.decimal);
    const [showDialog, setShowDialog] = useState(false);

    const connectWallet = (): boolean => {
        activate(injected)
        return true 
    }
    const navigate: (path:string)=>void = useNavigate();


    return (<div className={classes.Header}>
        <img src={Logo} alt="PEAKDEFI LOTTERY" className={classes.Logo}/>
        { !account && <ButtonLight onClick={connectWallet}>
            Connect Wallet
        </ButtonLight>}

        {account &&
                    <div
                        className={classes.connectedButton}
                        onClick={() => {
                            setShowDialog(true);
                        }}
                    >

                        <div className={classes.balanceDiv}>
                            <span><b>{(balance / Math.pow(10, decimals)).toFixed(2)}</b>   PEAK</span>
                        </div>

                        <div className={classes.splitter}>
                        </div>

                        <div
                            className={classes.addressDiv}
                        >
                            {"..." + account.substring(account.length - 8, account.length)}
                            <div className={classes.personIconDiv}>
                                <img src={Person} className={classes.personIcon} />
                            </div>
                        </div>

                    </div>
        }
        <AccountDialog show={showDialog} setShow={setShowDialog} address={account} disconnect={deactivate} />
    </div>);
}
 
export default Header;
