import React from "react"
import ContentLoader from "react-content-loader"

const MatchLoader = (props: any) => (
    <ContentLoader
        speed={2}
        backgroundColor="rgb(0,0,0)"
        foregroundColor="rgb(0,0,0)"
        backgroundOpacity={0.05}
        foregroundOpacity={0.1}
        height={"5em"}
        width={"100%"}

        {...props}
    >
        <rect x="0" y="0" rx="6px"  width="100%" height="2em" />
        <rect x="50%" y="2.5em" rx="5px" width="50%" height="1em" />
    </ContentLoader>
)

export default MatchLoader;