import { FunctionComponent, ReactNode } from "react";

import classes from './ButtonOrange.module.scss';

interface ButtonOrangeProps {
    onClick?: () => boolean | void,
    className?: string,
    children: ReactNode
}

const ButtonOrange: FunctionComponent<ButtonOrangeProps> = ({ onClick, children, className }) => {
    return (<button onClick={onClick} className={classes.ButtonOrange + ' ' + className}>
        {children}
    </button>);
}

export default ButtonOrange;