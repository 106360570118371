import React from "react"
import ContentLoader from "react-content-loader"

const PrizePotLoader = (props: any) => (
    <ContentLoader
        speed={2}
        backgroundColor="rgb(0,0,0)"
        foregroundColor="rgb(0,0,0)"
        backgroundOpacity={0.05}
        foregroundOpacity={0.1}
        height={"5em"}
        width={"100%"}
        rtl={true}

        {...props}
    >
        <rect x="0" y="0" rx="6px"  width="6em" height="3em" />
        <rect x="0" y="3.5em" rx="5px" width="4em" height="1.5em" />
    </ContentLoader>
)

export default PrizePotLoader;