import { useEffect, useState } from 'react';
import { TbFilter } from 'react-icons/tb';
import { HiArrowLeft, HiArrowRight } from 'react-icons/hi';
import ReactPaginate from 'react-paginate';


import ButtonBlue from '../../../../Common/ButtonBlue/ButtonBlue';
import classes from './RefereesTable.module.scss'
import AddressFilter from './components/AddressFilter/AddressFilter';
import DateFilter from './components/DateFilter/DateFilter';
import ComissionFilter from './components/ComissionFilter/ComissionFilter';
import { getReferees } from '../../../../../API/referal';
import { useWeb3React } from '@web3-react/core';
import CopyIcon from './source/Copy.svg';
import { toast } from 'react-toastify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface IColumn {
    title: string,
    name: string,
    width?: string
}

interface IReferee {
    [address: string]: any,
    date: string,
    commission: string
}

interface IPaginationPage {
    selected: number
}

const RefereesTable = () => {
    const [filtersCount, setFiltersCount] = useState<number>(0);
    const [showFilters, setShowFilters] = useState<boolean>(false);

    const { account } = useWeb3React();

    //filters state
    const [filterAddresses, setFilterAddresses] = useState<string[]>([]);
    const [startDate, setStartDate] = useState<Date | undefined>();
    const [endDate, setEndDate] = useState<Date | undefined>();
    const [comissionFrom, setComissionFrom] = useState<number>(0);
    const [comissionTo, setComissionTo] = useState<number>(0);

    //pagination state
    const [pageCount, setPageCount] = useState<number>(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const pageCountOptions = [5, 10, 25, 50];

    useEffect(() => {
        let filtersCount = 0;
        if (filterAddresses.length > 0)
            filtersCount++;
        if (!!startDate || !!endDate)
            filtersCount++;
        if (comissionFrom > 0 || comissionTo > 0)
            filtersCount++;

        setFiltersCount(filtersCount);


        if (account) {
            getReferees(account,
                {
                    referrals: filterAddresses,
                    dateFrom: !!startDate ? Math.floor(startDate.getTime() / 1000) : undefined,
                    dateTo: !!endDate ? Math.floor(endDate.getTime() / 1000) : undefined,
                    comissionFrom: comissionFrom,
                    comissionTo: comissionTo,
                    page: currentPage,
                    perPage: rowsPerPage
                }
            ).then((response: any) => {
                setPageCount(response.data.page_numbers);
                setValues(response.data.referral_purchased_tickets.map((e: any[]) => {
                    return {
                        address: e[0],
                        commission: e[1],
                        date: new Date(e[2] * 1000).toLocaleDateString('en-GB')
                    }
                }))
            })
        }
    }, [currentPage, rowsPerPage, account, startDate, endDate, comissionFrom, comissionTo, filterAddresses])

    const columns: IColumn[] = [
        { title: 'Address', name: 'address', width: '1fr' },
        { title: 'Date', name: 'date', width: '1fr' },
        { title: 'Commission', name: 'commission', width: '1fr' }
    ]

    const [values, setValues] = useState<IReferee[]>([]);

    const onPageChange = (selectedItem: IPaginationPage) => {
        setCurrentPage(selectedItem.selected + 1);
    }

    const clearAllFilters = () => {
        setFilterAddresses([]);
        setStartDate(undefined);
        setEndDate(undefined);
        setComissionFrom(0);
        setComissionTo(0);
        setFiltersCount(0);
    }

    const createLink = ()=>{
        navigator.clipboard.writeText(window.location.host + "?referrer_wallet_address=" + account);

        toast.info('Referral link copied to clipboard', {
            icon: ({ theme, type }) => <ContentCopyIcon style={{ color: 'rgb(53, 150, 216)' }} />,
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    return  (<> {  !account ? <div> </div> :  <section className={classes.RefereesTable}>

        <header>
            <h1>Referral Rewards History</h1>

            <div className={classes.filterControls}>
                <button className={classes.clearAll} onClick={clearAllFilters}>Clear All</button>

                <ButtonBlue
                    className={showFilters ? classes.filterButtonActive : classes.filterButton}
                    onClick={() => setShowFilters(!showFilters)}
                >
                    <TbFilter className={classes.filterIcon} />
                    <div className={classes.text}>Filters</div>
                    {
                        filtersCount > 0 &&
                        <div className={classes.filterCounter}>
                            {filtersCount}
                        </div>
                    }
                </ButtonBlue>
            </div>
        </header>

        <main>
            <div className={showFilters ? classes.filters : classes.collapsedFilters}>
                <div className={classes.filterSection}>
                    <h1>Filter by Address</h1>
                    <AddressFilter
                        filterAddresses={filterAddresses}
                        setFilterAddresses={setFilterAddresses}
                    />
                </div>

                <div className={classes.filterSection}>
                    <h1>Date</h1>
                    <DateFilter
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                    />
                </div>

                {/* <div className={classes.filterSection}>
                    <h1>Commission</h1>
                    <ComissionFilter
                        setComissionFrom={setComissionFrom}
                        setComissionTo={setComissionTo}
                        comissionFrom={comissionFrom}
                        comissionTo={comissionTo}
                    />
                </div> */}
            </div>
            <table>
                <tr className={classes.tableHeader} style={{ gridTemplateColumns: columns.map(e => e.width).join(' ') }}>
                    {
                        columns.map((e: IColumn) => {
                            return <th  >{e.title}</th>
                        })
                    }
                </tr>

                {
                    values.map((e: IReferee) => {
                        return <tr style={{ gridTemplateColumns: columns.map(e => e.width).join(' ') }}>
                            {columns.map((column: IColumn) => {
                                let len =  column.name == "address" ? e[column.name].length : 0
                                return <td>{ column.name == "address" ? e[column.name].slice(0,4)+"...."+"" + e[column.name].slice(len-4,len) : e[column.name]} </td>
                            })}
                        </tr>
                    })
                }

                {
                    values.length === 0 && account &&
                    <tr className={classes.noReferees}>
                        <h1>There are currently no entries.</h1>
                        <h1>Invite your friends now to earn PEAK:</h1>
                        <div className={classes.referralLink}>
                            <h1>Your referral: </h1>
                            <div className={classes.linkInput}>
                                <div className={classes.link}>{window.location.host + "....." + account.slice(account.length - 10, account.length)}</div>
                                <img style={{ "width": "15px", "height": "24px" }} src={CopyIcon} onClick={createLink} />
                            </div>
                        </div>

                    </tr>
                }

                {
                    !account &&
                    <tr className={classes.connectYourWallet}>
                        <td>Connect your wallet</td>
                    </tr>
                }
            </table>
        </main>
        {values.length !== 0 && <footer>
            <ReactPaginate
                className={classes.pagination}
                breakClassName={classes.break}
                pageClassName={classes.page}
                activeClassName={classes.pageActive}
                previousClassName={classes.previous}
                nextClassName={classes.next}
                previousLinkClassName={classes.previousA}
                nextLinkClassName={pageCount == 0 ? classes.disabledArrows : classes.nextA}
                disabledClassName={classes.disabledArrows}

                pageCount={pageCount}
                initialPage={0}
                marginPagesDisplayed={3}
                pageRangeDisplayed={2}
                breakLabel="..."
                previousLabel={<><HiArrowLeft className={classes.arrowLeft} /> <p>Prev</p></>}
                nextLabel={<><p>Next</p> <HiArrowRight className={classes.arrowRight} /></>}
                onPageChange={onPageChange}
            />

            <div className={classes.rowsPerPage}>
                Rows per page:
                <select value={rowsPerPage} onChange={(e) => { setRowsPerPage(parseInt(e.target.value)) }}>
                    {
                        pageCountOptions.map((e: number) => <option value={e}>{e}</option>)
                    }
                </select>
            </div>
        </footer>
        }


    </section>  } </>);
}

export default RefereesTable;