import { FunctionComponent, useEffect } from "react";

import classes from './DrawCountdown.module.scss'

interface DrawCountdownProps {
    timeLeft: number
}

const DrawCountdown: FunctionComponent<DrawCountdownProps> = ({ timeLeft }) => {

    const timestampToString = (timeLeft: number): string => {
        let d: number = Math.floor(timeLeft / (3600 * 24));
        let h: number = Math.floor(timeLeft % (3600 * 24) / 3600);
        let m: number = Math.floor(timeLeft % 3600 / 60);

        if (d === 0) {
            if (h === 0) {
                return m + 'm';
            }
            return h + 'h ' + m + 'm';
        }

        return d + 'd ' + h + 'h ' + m + 'm';
    }

    return (<div className={classes.DrawCountdown}>
        {timeLeft > 0 && <h2 className={classes.getYourTickets}>Get your tickets now!</h2>}
        {timeLeft <= 0 && <h2 className={classes.getYourTickets}>The round is over.</h2>}

        {timeLeft > 0 &&
            <>
                <h1 className={classes.countDown}>{timestampToString(timeLeft)}</h1>
                <h2 className={classes.untilTheDraw}>Until the draw</h2>
            </>
        }

        {timeLeft <= 0 &&
            <h2 className={classes.pleaseWait}>
                Please wait until the beginning of the next round.
            </h2>
        }
    </div>);
}

export default DrawCountdown;