import { FunctionComponent, ReactNode } from "react";

import classes from './ButtonBlue.module.scss';

interface ButtonBlueProps {
    onClick?: () =>any,
    children: ReactNode,
    className?: string
}
 
const ButtonBlue: FunctionComponent<ButtonBlueProps> = ({onClick, children, className }) => {
    return (<button onClick={onClick} className={classes.ButtonLight + ' ' + className}>
        {children}
    </button>);
}
 
export default ButtonBlue;