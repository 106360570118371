import { FunctionComponent } from "react";
import classes from './QnA.module.scss';
import Collapsible from 'react-collapsible';
import {BiPlusCircle} from 'react-icons/bi'

interface QnAProps {

}

interface IQnA {
    a: string,
    q: string
}

const QnA: FunctionComponent<QnAProps> = () => {

    const questionsAndAnswers: IQnA[] = [
        {
            q: "Which wallets are supported?",
            a: "As of now, the lottery only supports Metamask."
        },

        {
            q: "Which cryptocurrency can be used for the lottery?",
            a: "To buy tickets, you must deposit PEAK tokens. If you win the lottery, you will also receive PEAK tokens. Make sure to have some BNB in your wallet to approve transactions."
        },

        {
            q: "Where can I buy PEAK tokens?",
            a: "You can purchase PEAK on PancakeSwap, MEXC, Uniswap, or ProBit."
        },

        {
            q: 'How many tickets can I purchase per round?',
            a: "This number is unlimited."
        },

        {
            q: "Which percentage will of the deposits are distributed towards the prize pool?",
            a: "95%, 5% will be sent to a burn address."
        },

        {
            q: "How much is a ticket?",
            a: "One ticket costs $3 in PEAK tokens. "
        },

        {
            q: "How often can I win?",
            a: "There is one draw every Saturday at 10 AM CET."
        },

        {
            q: "What happens if no one wins the price after 7 days? ",
            a: "The price will be automatically added to the next prize pool."
        },

        {
            q: "How are the ticket numbers chosen and stored?",
            a: "All numbers are either automatically generated on-chain or can be chosen by the user. After the transaction, the numbers cannot be changed and are stored on the blockchain.",
        },

        {
            q: "How will the winning numbers be selected?",
            a: "The winning numbers are selected and stored on-chain, without any human input."
        },

        {
            q: "How can I prove that the winner is really the winner?",
            a: "His chosen numbers and the winning number can be found on the blockchain with a timestamp."
        }
    ]

    return (<section className={classes.QnA}>
        <div className={classes.innerQnA}>
            <h1>FAQ</h1>
            <div className={classes.questions}>
                {
                    questionsAndAnswers.map((e: IQnA) => {
                        return (
                            <Collapsible
                                trigger={<div className={classes.innerTrigger}><div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}><BiPlusCircle className={classes.icon}/></div><div>{e.q}</div></div>}
                                className={classes.collapsible}
                                openedClassName={classes.collapsible}
                                triggerClassName={classes.trigger}
                                triggerOpenedClassName={classes.trigger}

                                contentInnerClassName={classes.answer}
                            >
                                <div>{e.a}</div>
                            </Collapsible>
                        )
                    })
                }
            </div>
        </div>

    </section>);
}

export default QnA;