import { createSlice } from "@reduxjs/toolkit";

export const userInfoSlice = createSlice({
    name: 'wallet',
    initialState: {
        boughtTickets: 0
    }, 

    reducers: {

        setBoughtTickets: (state, action) => {
           return {...state, boughtTickets: action.payload}
        },
        
    },
});

export const {setBoughtTickets} = userInfoSlice.actions;
export default userInfoSlice.reducer;