import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import RoundInfo from "./components/RoundInfo/RoundInfo";

import classes from './FinishedRoundsAndHistory.module.scss'

interface FinishedRoundsAndHistoryProps {
    
}
 
const FinishedRoundsAndHistory: FunctionComponent<FinishedRoundsAndHistoryProps> = () => {
    const currentIndex = useSelector<any, number>((state: any) => state.lotteryInfo.currentIndex)

    if (currentIndex <= 1)
        return <div style={{justifyContent:'center', display:'flex'}}> <div className={classes.line}></div> </div>
    return (
            <section className={classes.FinishedRoundsAndHistory}>
                <div className={classes.innerFinishedRoundsAndHistory}>
                    <h1>Finished Rounds & History</h1>
                    <RoundInfo />
                </div>
            </section>  
    )
}
 
export default FinishedRoundsAndHistory;