import { FunctionComponent } from "react";
import ButtonOrange from "../../../../../../Common/ButtonOrange/ButtonOrange";
import { useWeb3React } from '@web3-react/core'
import { injected } from '../../../../../../../connector'


import Ticket from '../../images/Ticket.svg'

import classes from './NoWalletConnected.module.scss'

interface NoWalletConnectedProps {

}

const NoWalletConnected: FunctionComponent<NoWalletConnectedProps> = () => {

    const { activate, deactivate, account } = useWeb3React();

    const connectWallet = (): boolean => {
        activate(injected)
        return true;
    }

    return (<div className={classes.NoWalletConnected}>
        <div className={classes.textSection}>
            <h1>Connect your wallet to check if you've won!</h1>
            <ButtonOrange onClick={connectWallet}>
                Connect Wallet
            </ButtonOrange>
        </div>

        <img src={Ticket} alt='Ticket with PEAKDEFI logo' />
    </div>);
}

export default NoWalletConnected;