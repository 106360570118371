import { useWeb3React } from "@web3-react/core";
import { FunctionComponent } from "react";
import ReferralCard from "./components/ReferralCard/ReferralCard";
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';

import classes from './Referral.module.scss';


import Illustration from './source/Illustration.svg'

interface ReferralProps {
    setShowRefereesTable: (isOpen: boolean) => void,
    showRefereesTable: boolean
}

const Referral: FunctionComponent<ReferralProps> = (props) => {
    const { account } = useWeb3React();

    return (<section className={classes.Referral}>
        <div style={{ justifyContent: 'center', display: 'flex' }}> <div className={classes.line}></div> </div>

        <div className={classes.ReferralInner}>
            <div className={classes.textSection}>
                <h1>Refer friends.</h1>
                <h1>Play together.</h1>
                <h1>Receive 5% commission for every referral.</h1>
                <ReferralCard />
                {/* {account &&
                    <div
                        onClick={() => props.setShowRefereesTable(!props.showRefereesTable)}
                        className={classes.detailsToggle}
                    >
                        {props.showRefereesTable ? 'Hide' : 'Show'} details {props.showRefereesTable ? <AiFillCaretUp /> : <AiFillCaretDown />}
                    </div>
                } */}
            </div>

            <img src={Illustration} alt="Referral Illustration" />

        </div>

    </section>);
}

export default Referral;