import { FunctionComponent } from "react";
import ModalBase from "../../../../Common/ModalBase/ModalBase";

import Mexc from './images/mexc1.png';
import BiBoxLogo from './images/bibox.svg';
import PancakeLogo from './images/pancakeswap.svg';
import UniswapLogo from './images/uniswap.svg';
import HitBtc from './images/hitbtc.png';

import classes from './ExchangersPopup.module.scss';


interface ExchangersPopupProps {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void;
}

interface IExchanger {
    icon: string,
    link: string
}

const ExchangersPopup: FunctionComponent<ExchangersPopupProps> = ({ isOpen, setIsOpen }) => {

    const supportedExchangers: IExchanger[] = [
        {
            link: 'https://www.mexc.com/exchange/PEAK_USDT',
            icon: Mexc
        },

        {
            link: 'https://www.bibox.com/zh/exchange/basic/PEAK_USDT',
            icon: BiBoxLogo
        },

        {
            link: 'https://pancakeswap.finance/swap',
            icon: PancakeLogo
        },

        {
            link: 'https://app.uniswap.org/#/swap?outputCurrency=0x633eE3fbE5ffc05bD44Ecd8240732fF9ef9Dee1d&chain=mainnet',
            icon: UniswapLogo
        },

        {
            link: 'https://hitbtc.com/peak-to-btc',
            icon: HitBtc
        }
    ]

    return (<ModalBase
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={"Supported exchanges:"}
    >
        <div className={classes.ExchangersPopup}>
            {supportedExchangers.map(exchanger => <a href={exchanger.link}>
                <img src={exchanger.icon} alt="exchanger" />
            </a>
            )}
        </div>
    </ModalBase>);
}

export default ExchangersPopup;