import { FunctionComponent, useEffect, useState } from "react";

import classes from './BuyInformationSection.module.scss';
import BuyInfoCard from "./components/BuyInfoCard/BuyInfoCard";
import DrawCountdown from "./components/DrawCountdown/DrawCountdown";
import { useSelector } from 'react-redux';

interface BuyInformationSectionProps {

}

const BuyInformationSection: FunctionComponent<BuyInformationSectionProps> = () => {

    const date = new Date()
    const timeEnd = useSelector((state: any) => new Date(state.lotteryInfo.timeEnd * 1000));
    const [timeUntilDraw, setTimeUntilDraw] = useState<number>(0);

    const timerFunction = (): void => {
        setTimeUntilDraw(prevTime => prevTime - 1);
    }

    useEffect(() => {
        let intervalId = setInterval(timerFunction, 10000);

        return () => {
            clearInterval(intervalId);
        }
    }, [])

    useEffect(()=>{
        console.log("TIME END: " + timeEnd)
    }, [timeEnd])

    return (<>
        <section className={classes.BuyInformationSection}>
            <DrawCountdown timeLeft={(timeEnd.getTime() - date.getTime()) / 1000} />
            {(((timeEnd.getTime() - date.getTime()) / 1000) > 0) && <BuyInfoCard />}
            {(((timeEnd.getTime() - date.getTime()) / 1000) <= 0) &&
                <div className={classes.drumWrapper}>
                    <img className={classes.drum} alt="drum" src='https://peakdefi-lottery-sources.s3.eu-central-1.amazonaws.com/Drum+GIF.gif' />
                </div>
            }
        </section>
        {(((timeEnd.getTime() - date.getTime()) / 1000) <= 0) && <div className={classes.filler}></div>}
    </>);
}

export default BuyInformationSection;