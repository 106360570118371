import { FunctionComponent } from "react";
import BuyTickets from "./components/BuyTickets/BuyTickets";
import TiltedCards from "./components/TiltedCards/TiltedCards";

import Ball from './images/Ball.png'
import Logo from './images/Logo.png';

import classes from './TitleSection.module.scss';


interface TitleSectionProps {
    
}
 
const TitleSection: FunctionComponent<TitleSectionProps> = () => {
    return (<section className={classes.TitleSection}>
        <div className={classes.titleSectionOutline}></div>
        <div className={classes.innerTitleSection}>
            <img src={Ball} alt='3d ball' className={classes.tdBall}/>
            <img src={Logo} alt='3d PEAKDEFI logo' className={classes.tdLogo}/>
            <div className={classes.centralSection}>
                <h1>PEAKDEFI LOTTERY</h1>
                <div className={classes.buyTickets}>
                    <BuyTickets />
                    <TiltedCards />
                </div>
            </div>
        </div>
    </section>);
}
 
export default TitleSection;