import { FunctionComponent, useEffect, useState } from "react";
import ButtonOrange from "../../../../Common/ButtonOrange/ButtonOrange";

import Ticket from './images/Ticket.svg'

import classes from './ConnectWallet.module.scss';

import { useWeb3React } from '@web3-react/core'
import { injected } from '../../../../../connector'
import NoWalletConnected from "./components/NoWalletConnected/NoWalletConnected";
import WalletConnected from "./components/WalletConnected/WalletConnected";
import LoseScreen from "./components/LoseScreen/LoseScreen";
import WinScreen from "./components/WinScreen/WinScreen";
import WinningInfo from "./components/WinningInfo/WinningInfo";
import { useSelector } from "react-redux";

interface ConnectWalletProps {

}

const ConnectWallet: FunctionComponent<ConnectWalletProps> = () => {
    const { activate, deactivate, account } = useWeb3React();
    const [isAWinner, setIsAWinner] = useState<boolean>(false);
    const [showWinner, setShowWinner] = useState<boolean>(false);
    const [reward, setReward] = useState<number>(0);

    const [showWinningInfo, setShowWinningInfo] = useState<boolean>(false);
    const lotteryId = useSelector((state: any) => state.lotteryInfo.userSelectIndex);

    const connectWallet = () : boolean=>{
        activate(injected)
        return true;
    }

    useEffect(()=>{
        if(showWinner)
            setShowWinningInfo(true);
    }, [showWinner])

    useEffect(()=>{
        setShowWinner(false);
    }, [lotteryId])


    return (<section className={classes.ConnectWallet}>
        {!account && <NoWalletConnected />}
        {!!account && <>
            {!showWinner && <WalletConnected setIsAWinner={setIsAWinner} setShowWinner={setShowWinner}/>}
            {showWinner && !isAWinner && <LoseScreen />}
            {showWinner && isAWinner && <WinScreen />}
        </>}
        <WinningInfo isOpen={showWinningInfo} setIsOpen={setShowWinningInfo} />
    </section>);
}

export default ConnectWallet;