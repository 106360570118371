import { createSlice } from "@reduxjs/toolkit";

export const winnerSlice = createSlice({
    name: 'wallet',
    initialState: {
        winningTickets: [],
        isClaimed: false,
        totalReward: 0
    }, 

    reducers: {
        setWinningTickets: (state, action) =>{
            return {...state, winningTickets: action.payload}
        },

        setIsClaimed: (state, action) =>{
            return {...state, isClaimed: action.payload}
        },

        setTotalReward: (state, action) =>{
            return {...state, totalReward: action.payload}
        },
        
    },
});

export const {setWinningTickets, setIsClaimed, setTotalReward} = winnerSlice.actions;

export default winnerSlice.reducer;