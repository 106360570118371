import { createSlice } from "@reduxjs/toolkit";

export const selectedLotterySlice = createSlice({
    name: 'wallet',
    initialState: {
        selectedLottery: {
            amountCollectedInPeak: 0,
            backetNumber: [],
            backetPrice: [],
            countWinnersPerBracket: [],
            endTime: 0,
            finalNumber: 0,
            peakPerBracket: [],
            priceTicketInPeak: 0,
            rewardsBreakdown: [],
            startTime: 0,
            status: 0,
            treasuryFee: 0
        }
    }, 

    reducers: {
        setSelectedLottery: (state, action)=> {
            if(action.payload===undefined)
                return {...state, selectedLottery: {
                                            amountCollectedInPeak: 0,
                                            backetNumber: [],
                                            backetPrice: [],
                                            countWinnersPerBracket: [],
                                            endTime: 0,
                                            finalNumber: 0,
                                            peakPerBracket: [],
                                            priceTicketInPeak: 0,
                                            rewardsBreakdown: [],
                                            startTime: 0,
                                            status: 0,
                                            treasuryFee: 0
                                        }}
            return {...state, selectedLottery: action.payload}
        }
    },
});

export const {setSelectedLottery} = selectedLotterySlice.actions;

export default selectedLotterySlice.reducer;