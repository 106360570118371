import { FunctionComponent } from "react";
import GenericPage from "../../Common/GenericPage/GenericPage";

import classes from './FAQ.module.scss';

interface FAQProps {

}

const FAQ: FunctionComponent<FAQProps> = () => {
    return (
        <GenericPage title="Frequently asked questions">
            <div className={classes.FAQProps}>
                
            </div>
        </GenericPage>
    );
}

export default FAQ;