import { ClassicComponent, FunctionComponent, ReactNode } from "react"
import FAQ from "./components/Pages/FAQ/FAQ";

import MainPage from './components/Pages/MainPage/MainPage'
import TermsAndConditions from "./components/Pages/TermsAndConditions/TermsAndConditions";

interface IRoute{
    path: string,
    element: ReactNode
}

const routes: IRoute[] =[
    {
        path: '/',
        element: <MainPage />
    },

    {
        path: '/terms-and-conditions',
        element: <TermsAndConditions />
    },

    {
        path: '/faq',
        element: <FAQ />
    }
]

export default routes;