import { configureStore } from '@reduxjs/toolkit'
import userWalletSlice from '../features/userWalletSlice'
import contractsSlice from '../features/contractsSlice'
import lotteryInfoSlice from '../features/lotteryInfoSlice'
import selectedLotterySlice from '../features/selectedLotterySlice'
import modalsSlice from '../features/modalsSlice'
import winnerSlice from '../features/winnerSlice'
import userInfoSlice from '../features/userInfoSlice'

export default configureStore({
    reducer: {
        userWallet: userWalletSlice,
        contracts: contractsSlice,
        lotteryInfo: lotteryInfoSlice,
        selectedLotterySlice: selectedLotterySlice,
        modals: modalsSlice,
        winner: winnerSlice,
        userInfo: userInfoSlice
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  })