import { FunctionComponent } from "react";

import classes from './ReferralCard.module.scss';
import ButtonOrange from "../../../../../../Common/ButtonOrange/ButtonOrange";
import { useSelector } from "react-redux";
import { useWeb3React } from '@web3-react/core'
import CopyIcon from '../../source/Copy.svg'
import { toast } from 'react-toastify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { injected } from '../../../../../../../connector'


interface ReferralCard { }

const ReferralCard: FunctionComponent<ReferralCard> = () => {

    const { activate, account } = useWeb3React();

    const peakPrice = useSelector((state: any) => state.lotteryInfo.peakPrice);
    const referralAmount = useSelector((state: any) => state.userWallet.referralReward)
    const referralAddress = useSelector((state: any) => state.userWallet.referrerWallet)
    const lotteryContract = useSelector((state: any) => state.contracts.lotteryContract)
    const referralContract = useSelector((state: any) => state.contracts.referralContract)
    const decimals = useSelector((state: any) => state.userWallet.decimal)

    const createLink = () => {
        navigator.clipboard.writeText(window.location.host + "?referrer_wallet_address=" + account);

        toast.info('Referral link copied to clipboard', {
            icon: ({ theme, type }) => <ContentCopyIcon style={{ color: 'rgb(53, 150, 216)' }} />,
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const copyReferrerWallet = () => {
        navigator.clipboard.writeText(referralAddress);

        toast.info('Referrer address copied to clipboard', {
            icon: ({ theme, type }) => <ContentCopyIcon style={{ color: 'rgb(53, 150, 216)' }} />,
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const connectWallet = (): boolean => {
        activate(injected)
        return true
    }

    const claimReward = (): void => {
        referralContract.claimReward()
    }
    function numberWithCommas(x: number) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (<>
        {!account && <ButtonOrange onClick={connectWallet}>
            Connect Wallet
        </ButtonOrange>}

        {!!account && <>
            <div className={classes.ReferralCard}>
                <div style={{ "borderBottom": "2px solid rgba(12, 103, 254, 0.2)" }} className={classes.row}>
                    <div className={classes.headerSection}>
                        <div className={classes.textBlock}>
                            <div className={classes.text}> Your total Rewards </div>
                            <div className={classes.amount}> PEAK {numberWithCommas(referralAmount)} </div>
                        </div>

                        <div style={{ "display": "flex", "flexDirection": "column", "justifyContent": "center" }}>
                            <ButtonOrange onClick={claimReward}>
                                Claim
                            </ButtonOrange>
                        </div>

                    </div>
                </div>
                <div style={{ "borderBottom": "2px solid rgba(12, 103, 254, 0.2)" }} className={classes.row}>
                    <div className={classes.headerSection}>
                        <div className={classes.text}> Referral Link: </div>
                        <div className={classes.referralLink}>
                            <div className={classes.link}>{window.location.host + "....." + account.slice(account.length - 4, account.length)}</div>
                            <img style={{ "width": "15px", "height": "24px" }} src={CopyIcon} onClick={createLink} />
                        </div>
                    </div>
                </div>
                <div style={referralAddress == "0x0000000000000000000000000000000000000000" ? {display:"none"}: {}} className={classes.row}>
                    <div className={classes.headerSection}>
                        <div className={classes.text}> Referrer wallet address: </div>
                        <div className={classes.referralLink}>
                            <div className={classes.link}> { referralAddress.slice(0, 4) + "...." + referralAddress.slice(account.length - 4, account.length)}  </div>
                            <img style={{ "width": "15px", "height": "24px" }} src={CopyIcon} onClick={copyReferrerWallet} />
                        </div>
                    </div>
                </div>
            </div>
        </>
        }

    </>
    );
}

export default ReferralCard;