import { FunctionComponent } from "react";

import classes from './WinningNumber.module.scss'

import Ball0 from './images/0.svg';
import Ball1 from './images/1.svg';
import Ball2 from './images/2.svg';
import Ball3 from './images/3.svg';
import Ball4 from './images/4.svg';
import Ball5 from './images/5.svg';
import Ball6 from './images/6.svg';
import Ball7 from './images/7.svg';
import Ball8 from './images/8.svg';
import Ball9 from './images/9.svg';
import WinningNumberLoader from "../../../../../../../../Common/ContentLoaders/WinningNumberLoader/WinningNumberLoader";

interface WinningNumberProps {
    winningNumber: string[],
    isLoading?: boolean
}

const WinningNumber: FunctionComponent<WinningNumberProps> = ({ winningNumber, isLoading }) => {
    const BallImages: string[] = [
        Ball0,
        Ball1,
        Ball2,
        Ball3,
        Ball4,
        Ball5,
        Ball6,
        Ball7,
        Ball8,
        Ball9
    ]

    return (<div className={classes.WinningNumber}>
        <h1>Winning number</h1>
        <div className={classes.Balls}>

            {
                isLoading ?
                    <WinningNumberLoader /> :
                    winningNumber.slice(0, winningNumber.length -1).map(number => {
                        return <img src={BallImages[parseInt(number)]} alt={number} />
                    })
            }
        </div>
    </div>);
}

export default WinningNumber;