import { FunctionComponent, useEffect, useState } from "react";
import ModalBase from "../../../../../../../../Common/ModalBase/ModalBase";
import TicketEditor from "./components/TicketEditor/TicketEditor";


interface MyTicketsModalProps {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void
}

const MyTicketsModal: FunctionComponent<MyTicketsModalProps> = ({ isOpen, setIsOpen }) => {


    return (<ModalBase
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={'Your tickets'}
        style={{width: "50vw", maxWidth: "800px"}}
    >
      <TicketEditor />
    </ModalBase>);
}

export default MyTicketsModal;