import { FunctionComponent } from "react";

import classes from './TiltedCards.module.scss'

import CardDark from './images/CardDark.svg';
import CardLight from './images/CardLight.svg';

interface TiltedCardsProps {
    
}
 
const TiltedCards: FunctionComponent<TiltedCardsProps> = () => {
    return (<div className={classes.TiltedCards}>
        <img src={CardDark} alt='tilted light ticket' className={classes.cardLight}/>
        <img src={CardLight} alt='tilted dark ticket' className={classes.cardDark}/>
    </div>);
}
 
export default TiltedCards;