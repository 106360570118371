import { ethers } from "ethers";
import { FunctionComponent, useEffect, useState } from "react";
import ModalBase from "../../../../../../Common/ModalBase/ModalBase";
import classes from './WinningInfo.module.scss';
import { abi as tokenAbi, tokenContractAddress } from '../../../../source/tokenAbi';
import { abi as lotteryAbi, lotteryContractAddress } from '../../../../source/lotteryAbi';
import { useWeb3React } from "@web3-react/core";
import { useDispatch, useSelector } from "react-redux";
import PoppyTable from "../../../../../../Common/PoppyTable/PoppyTable";
import { IColumn } from "../../../../../../../types/table";
import { getPrice } from "../../../../../../../API/tokenPrice";
import ButtonOrange from "../../../../../../Common/ButtonOrange/ButtonOrange";
import { toast } from "react-toastify";
import { setIsClaimed } from "../../../../../../../features/winnerSlice";

interface WinningInfoProps {
    isOpen: boolean;
    setIsOpen: (state: boolean) => void;
}

interface IWinningTicketInfo {
    ticketId: number,
    ticketNumber: number,
    matchedDigits: number,
    reward: number
}

const WinningInfo: FunctionComponent<WinningInfoProps> = (props) => {
    const roundNumber = useSelector((state: any) => state.lotteryInfo.userSelectIndex);

    const [matchInfo, setMatchInfo] = useState<number[]>([0, 0, 0, 0, 0, 0]);

    const { account } = useWeb3React();
    const lotteryId = useSelector((state: any) => state.lotteryInfo.userSelectIndex);
    const peakPerBracket = useSelector((state: any) => state.selectedLotterySlice.selectedLottery.peakPerBracket);
    const [singleTicketPrize, setSingleTicketPrize] = useState<number[]>([0, 0, 0, 0, 0, 0]);
    const countWinnersPerBracket = useSelector((state: any) => state.selectedLotterySlice.selectedLottery.countWinnersPerBracket);
    const winningNumber = useSelector((state: any) => state.selectedLotterySlice.selectedLottery.finalNumber);
    const winningTickets = useSelector((state: any) => state.winner.winningTickets);
    const winAmount = useSelector((state: any) => state.winner.totalReward);

    const [matches, setMatches] = useState<IWinningTicketInfo[]>([]);
    const isClaimed = useSelector((state: any) => state.winner.isClaimed)

    const [PEAKprice, setPeakPrice] = useState<number>(0.25);
    const dispatch = useDispatch();

    useEffect(() => {
        getPrice().then((response: any) => {
            setPeakPrice(parseFloat(response.data.price.toFixed(3)));
        })
    }, [])

    const columns: IColumn[] = [
        { key: 'ticketId', title: 'Ticket no.' },
        { key: 'ticketNumber', title: 'Numbers' },
        { key: 'matchedDigits', title: 'Tier' },
        { key: 'reward', title: 'Prize' }
    ]

    const [rows, setRows] = useState<any[]>([]);

    useEffect(() => {
        setSingleTicketPrize(peakPerBracket.map((e: number, index: number) => {
            if (countWinnersPerBracket[index] === 0) {
                return 0;
            }
            return e;
        }))
    }, [peakPerBracket, countWinnersPerBracket])

    const matchChecker = (checkedNumber: number, matchNumber: number): number => {
        const matchNumberArray = Array.from(String(matchNumber), Number);
        const checkedNumberArray = Array.from(String(checkedNumber), Number);
        //convert number into array, subtract each digit, convert to string and retrieve length
        // [3, 0, 8, 2, 3, 0]  <= winning number
        // [3, 0, 1, 5, 7, 3]  <= checked number
        // ------------------
        // [0, 0, 7, 3, 4, 3]  <= subtracted difference

        // '007343'              <= converted to string
        // 7343                <= converted to integer and extracted length of missing matches

        const parsedNumber = parseInt(matchNumberArray.map((number: number, index: number) => {
            return Math.abs(number - checkedNumberArray[index]);
        }).join(''));

        if (parsedNumber === 0)
            return 6;

        return 6 - parsedNumber.toString().length;

    }

    const claimReward = async () => {
        const { ethereum } = window;
        let provider;
        if (ethereum) {
            provider = new ethers.providers.Web3Provider(ethereum)
        } else {
            provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_PROVIDER);
        }
        const signer = await provider.getSigner();
        let LotteryContract = new ethers.Contract(lotteryContractAddress, lotteryAbi, signer);
        debugger;
        LotteryContract.claimTickets(
            lotteryId,
            winningTickets.map((e: any) => e.ticketId),
            winningTickets.map((e: any) => e.matchedDigits-1)
        ).then((response: any) => {
            const transaction: Promise<any> = response.wait().then(() => { dispatch(setIsClaimed(true)) });

            toast.promise(
                transaction,
                {
                    pending: "Collecting your prize",
                    error: "Collect transaction failed",
                    success: "Congratulations! Your prize has been successfully collected"
                }
            )
        });
    }

    const checkNow = () => {
        const { ethereum } = window;
        let provider;
        if (ethereum) {
            provider = new ethers.providers.Web3Provider(ethereum)
        } else {
            provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_PROVIDER);
        }
        let LotteryContract = new ethers.Contract(lotteryContractAddress, lotteryAbi, provider);
        LotteryContract.viewUserInfoForLotteryId(account, lotteryId, 0, 10000).then((response: any) => {


            //generating array of numbers and number of their matches based on winning number
            const reveresedNumbers = response[1].map((e: number, index: number): number => {
                return parseInt([1].concat(Array.from(String(e), Number).slice(1).reverse()).join(''))
            });

            let matches = reveresedNumbers.map((e: number, index: number): IWinningTicketInfo => {
                return {
                    ticketId: response[0][index].toNumber(),
                    ticketNumber: e,
                    matchedDigits: matchChecker(e, parseInt([1].concat(Array.from(String(winningNumber), Number).slice(1).reverse()).join(''))),
                    reward: singleTicketPrize[matchChecker(e, parseInt([1].concat(Array.from(String(winningNumber), Number).slice(1).reverse()).join(''))) - 1]
                }
            })

            setMatches([...matches]);

            let matchedDigitsTicketsCount = [0, 0, 0, 0, 0, 0];

            for(let i=0; i<6; i++){
                matchedDigitsTicketsCount[i] = matches.filter((match: IWinningTicketInfo)=>match.matchedDigits===(i+1)).length;
            }

            setMatchInfo([...matchedDigitsTicketsCount]);
        })
    }

    useEffect(() => {
        checkNow();
    }, [])

    

    useEffect(() => {
        setRows(matches.map((match: IWinningTicketInfo) => {
            return {
                ticketId: match.ticketId,
                ticketNumber: Array.from(String(match.ticketNumber), Number).join(' '),
                matchedDigits: match.matchedDigits > 0 ?
                    match.matchedDigits === 6 ? 
                        "Match all 6" 
                        : 'Match first '+ match.matchedDigits 
                    : '-',
                reward: match.reward + ' PEAK($' + (match.reward * PEAKprice) + ')'
            }
        }))
    }, [matches]);

    return (<ModalBase
        className={classes.WinningInfo}
        isOpen={props.isOpen}
        setIsOpen={props.setIsOpen}
        title={<div className={classes.modalHeader}>
            <h1>Winning Tickets</h1>
            <h1>●</h1>
            <h1 className={classes.roundCounter}>Round {roundNumber}</h1>
        </div>}
    >
        <main>
            <div className={classes.tierInfo}>
                <h1>TIER:</h1>
                <div className={classes.tierGrid}>
                    {matchInfo.map((value: number, index) => {
                        return <div className={classes.singleTierInfo}>
                            <h2>Match {index + 1 === 6 ? 'all' : 'first'} {index + 1}</h2>
                            <h1>{value} tickets</h1>
                        </div>
                    })}
                </div>
            </div>
            <div className={classes.ticketInfoTable}>
                <PoppyTable
                    columns={columns}
                    rows={rows}
                />
            </div>
        </main>

        <footer>
            <div className={classes.yourPrize}>
                <h2>Your prize:</h2>
                <h1>${winAmount}</h1>
            </div>
            <ButtonOrange 
                className={classes.claimButton} 
                onClick={()=>{
                    if(!isClaimed)
                    claimReward();
                }} 
            >
                {isClaimed ? 'Reward already claimed' : 'Claim'}
            </ButtonOrange>
        </footer>
    </ModalBase>);
}

export default WinningInfo;