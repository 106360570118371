import { createSlice } from "@reduxjs/toolkit";

export const lotteryInfoSlice = createSlice({
    name: 'wallet',
    initialState: {
        timeEnd: 0,
        prizePeak: 0,
        prizeUSD: 0,
        peakPrice: 0,
        currentIndex: 1,
        userSelectIndex: 1,
        maxRoundsHistory: 1,
        lottery: {
            amountCollectedInPeak: 0,
            backetNumber: [],
            backetPrice: [],
            countWinnersPerBracket: [],
            endTime: 0,
            finalNumber: 0,
            peakPerBracket: [],
            priceTicketInPeak: 0,
            rewardsBreakdown: [],
            startTime: 0,
            status: 0,
            treasuryFee: 0
        }
    }, 

    reducers: {
        setTimeEnd: (state, action)=> {
            if(action.payload===undefined)
                return {...state, timeEnd: 0}
            return {...state, timeEnd: action.payload}
        },

        setMaxRoundsHistory: (state, action)=> {
            if(action.payload===undefined)
                return {...state, maxRoundsHistory: 1}
            return {...state, maxRoundsHistory: action.payload}
        },

        setPrizePeak: (state, action)=> {
            if(action.payload===undefined)
                return {...state, prizePeak: 0}
            return {...state, prizePeak: action.payload}
        },

        setPrizeUSD: (state, action)=> {
            if(action.payload===undefined)
                return {...state, prizeUSD: 0}
            return {...state, prizeUSD: action.payload}
        },

        setCurrentIndex: (state, action)=> {
            if(action.payload===undefined)
                return {...state, currentIndex: 1}
            return {...state, currentIndex: action.payload}
        },

        setUserSelectIndex: (state, action)=> {
            if(action.payload===undefined)
                return {...state, userSelectIndex: 1}
            return {...state, userSelectIndex: action.payload}
        },

        setPeakPrice: (state, action)=> {
            if(action.payload===undefined)
                return {...state, peakPrice: 0}
            return {...state, peakPrice: action.payload}
        },

        setLottery: (state, action)=> {
            if(action.payload===undefined)
                return {...state, lottery: {
                                            amountCollectedInPeak: 0,
                                            backetNumber: [],
                                            backetPrice: [],
                                            countWinnersPerBracket: [],
                                            endTime: 0,
                                            finalNumber: 0,
                                            peakPerBracket: [],
                                            priceTicketInPeak: 0,
                                            rewardsBreakdown: [],
                                            startTime: 0,
                                            status: 0,
                                            treasuryFee: 0
                                        }}
            return {...state, lottery: action.payload}
        }
    },
});

export const { setTimeEnd, setPrizePeak, setPrizeUSD, setMaxRoundsHistory,
    setCurrentIndex, setUserSelectIndex, setPeakPrice, setLottery } = lotteryInfoSlice.actions;

export default  lotteryInfoSlice.reducer;