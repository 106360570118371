import { ethers } from "ethers";
import { FunctionComponent, useEffect, useState } from "react";
import ButtonOrange from "../../../../../../Common/ButtonOrange/ButtonOrange";
import { abi as tokenAbi, tokenContractAddress } from '../../../../source/tokenAbi';
import { abi as lotteryAbi, lotteryContractAddress } from '../../../../source/lotteryAbi';

import TicketAndBalls from '../../images/TicketAndBalls.svg'

import classes from './WalletConnected.module.scss'
import { useWeb3React } from "@web3-react/core";
import { useDispatch, useSelector } from "react-redux";
import { setTotalReward, setWinningTickets } from "../../../../../../../features/winnerSlice";
import WinningInfo from '../WinningInfo/WinningInfo';

interface WalletConnectedProps {
    setIsAWinner: (isAWinner: boolean) => void,
    setShowWinner: (showWinner: boolean) => void
}

interface IWinningTicketInfo {
    ticketId: number,
    ticketNumber: number,
    matchedDigits: number,
    reward: number
}

const WalletConnected: FunctionComponent<WalletConnectedProps> = ({ setIsAWinner, setShowWinner }) => {

    const { account } = useWeb3React();
    const dispatch = useDispatch();

    const lotteryId = useSelector((state: any) => state.lotteryInfo.userSelectIndex);

    //reveres winningNumber
    const winningNumber = useSelector((state: any) => state.selectedLotterySlice.selectedLottery.finalNumber);

    //to calculate how much of a prize each ticket is
    const countWinnersPerBracket = useSelector((state: any) => state.selectedLotterySlice.selectedLottery.countWinnersPerBracket);
    const peakPerBracket = useSelector((state: any) => state.selectedLotterySlice.selectedLottery.peakPerBracket);
    const [singleTicketPrize, setSingleTicketPrize] = useState<number[]>([0, 0, 0, 0, 0, 0]);


    useEffect(() => {
        setSingleTicketPrize(peakPerBracket.map((e: number, index: number) => {
            if (countWinnersPerBracket[index] === 0){
                return 0;}
            return e;
        }))
    }, [peakPerBracket, countWinnersPerBracket])

    const matchChecker = (checkedNumber: number, matchNumber: number): number => {
        const matchNumberArray = Array.from(String(matchNumber), Number);
        const checkedNumberArray = Array.from(String(checkedNumber), Number);
        //convert number into array, subtract each digit, convert to string and retrieve length
        // [3, 0, 8, 2, 3, 0]  <= winning number
        // [3, 0, 1, 5, 7, 3]  <= checked number
        // ------------------
        // [0, 0, 7, 3, 4, 3]  <= subtracted difference

        // '007343'              <= converted to string
        // 7343                <= converted to integer and extracted length of missing matches

        const parsedNumber = parseInt(matchNumberArray.map((number: number, index: number) => {
            return Math.abs(number - checkedNumberArray[index]);
        }).join(''));

        if(parsedNumber===0)
            return 6;

        return 6 - parsedNumber.toString().length;

    }

    const checkNow = () => {
        const { ethereum } = window;
        let provider;
        if (ethereum) {
            provider = new ethers.providers.Web3Provider(ethereum)
        } else {
            provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_PROVIDER);
        }
        let LotteryContract = new ethers.Contract(lotteryContractAddress, lotteryAbi, provider);
        LotteryContract.viewUserInfoForLotteryId(account, lotteryId, 0, 10000).then((response: any) => {

            //generating array of numbers and number of their matches based on winning number
            const reveresedNumbers = response[1].map((e: number, index: number): number => {
                return parseInt([1].concat(Array.from(String(e), Number).slice(1).reverse()).join(''))
            });

            let matches = reveresedNumbers.map((e: number, index: number): IWinningTicketInfo => {
                return {
                    ticketId: response[0][index].toNumber(),
                    ticketNumber: e,
                    matchedDigits: matchChecker(e, parseInt([1].concat(Array.from(String(winningNumber), Number).slice(1).reverse()).join(''))),
                    reward: singleTicketPrize[matchChecker(e, parseInt([1].concat(Array.from(String(winningNumber), Number).slice(1).reverse()).join(''))) - 1] 
                }
            })

            matches = matches.filter((e: IWinningTicketInfo) => e.matchedDigits > 0);
            debugger;
            matches = matches.filter((e: IWinningTicketInfo) => e.reward>0);
            //calculating size of the reward
            dispatch(setTotalReward(matches.reduce((a: number, b: IWinningTicketInfo) => {
                return a + singleTicketPrize[b.matchedDigits - 1];
            }, 0)));

            dispatch(setWinningTickets([...matches]));
            console.log(matches)
            //we can inform a user about prize already without calculating the sum
            setIsAWinner(matches.length > 0);
            setShowWinner(true);
        })
    }

    return (<div className={classes.WalletConnected}>
        <div className={classes.textSection}>
            <h2>Are you a</h2>
            <h1>Winner?</h1>
            <ButtonOrange onClick={checkNow}>
                Check Now
            </ButtonOrange>
        </div>        
        <img src={TicketAndBalls} alt='Ticket with PEAKDEFI logo' /> 
    </div>);
}

export default WalletConnected;