import { FunctionComponent, useEffect, useState } from "react";
import { ethers } from "ethers";
import ButtonOrange from "../../../../../../Common/ButtonOrange/ButtonOrange";
import { abi as tokenAbi, tokenContractAddress } from '../../../../source/tokenAbi';
import { abi as lotteryAbi, lotteryContractAddress } from '../../../../source/lotteryAbi';
import WinLogo from './images/WinLogo.png'

import classes from './WinScreen.module.scss'
import { useDispatch, useSelector } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
import ButtonOrangeLight from "../../../../../../Common/ButtonOrangeLight/ButtonOrangeLight";
import Confetti from 'react-confetti'
import { setIsClaimed } from "../../../../../../../features/winnerSlice";
import {BsArrowRight} from 'react-icons/bs';
import WinningInfo from "../WinningInfo/WinningInfo";

interface WinScreenProps {

}

const WinScreen: FunctionComponent<WinScreenProps> = () => {
    const dispatch = useDispatch();

    const [winningTicketIds, setWinningTicketIds] = useState<any>([]);
    const winAmount = useSelector((state: any) => state.winner.totalReward);
    const winningTickets = useSelector((state: any) => state.winner.winningTickets);
    const isClaimed = useSelector((state: any) => state.winner.isClaimed)
    const { account } = useWeb3React();
    const [showWinningInfo, setShowWinningInfo] = useState<boolean>(false);

    const lotteryId = useSelector((state: any) => state.lotteryInfo.userSelectIndex);

    const claimReward = async () => {
        const { ethereum } = window;
        let provider;
        if (ethereum) {
            provider = new ethers.providers.Web3Provider(ethereum)
        } else {
            provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_PROVIDER);
        }
        const signer = await provider.getSigner();
        let LotteryContract = new ethers.Contract(lotteryContractAddress, lotteryAbi, signer);
        debugger;
        LotteryContract.claimTickets(
            lotteryId,
            winningTickets.map((e: any) => e.ticketId),
            winningTickets.map((e: any) => e.matchedDigits-1)
        ).then((response: any) => {
            const transaction: Promise<any> = response.wait().then(() => { dispatch(setIsClaimed(true)) });

            toast.promise(
                transaction,
                {
                    pending: "Collecting your prize",
                    error: "Collect transaction failed",
                    success: "Congratulations! Your prize has been successfully collected"
                }
            )
        });
    }

    useEffect(() => {
        if (winningTicketIds.length > 0) {
            const { ethereum } = window;
            let provider;
            if (ethereum) {
                provider = new ethers.providers.Web3Provider(ethereum)
            } else {
                provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_PROVIDER);
            }
            let LotteryContract = new ethers.Contract(lotteryContractAddress, lotteryAbi, provider);

            LotteryContract.claimTickets(lotteryId, winningTicketIds, 100000).then((response: any) => {
                const transaction = response.wait();

                toast.promise(
                    transaction,
                    {
                        pending: 'Claim ticket transaction pending',
                        success: 'Congratulations! Your ticket have been claimed',
                        error: "Claim failed"
                    }
                )
            })

        }
    }, [winningTicketIds])

    return (<div className={classes.WinScreen}>
        <Confetti
            className={classes.confetti}
            numberOfPieces={1000}
            tweenDuration={50000}
            gravity={0.2}
            recycle={false}

        />
        <div className={classes.textSection}>
            <h1>Congratulations!</h1>
            <h1>You're the lucky one.</h1>
            <div className={classes.prizeSize}>
                <h2>Your prize:</h2>
                <h1>{winAmount.toFixed(2) } PEAK</h1>
            </div>
            <ButtonOrangeLight className={classes.claimButton} onClick={() => { claimReward() }} disabled={isClaimed}>
                {!isClaimed ? 'Collect' : 'Prize has been claimed already'}
            </ButtonOrangeLight>
            <div className={classes.viewButton} onClick={()=>setShowWinningInfo(true)}>View <BsArrowRight /></div>
        </div>
        <img src={WinLogo} alt='Ticket with PEAKDEFI logo' />
        <WinningInfo isOpen={showWinningInfo} setIsOpen={setShowWinningInfo} />
    </div>);
}

export default WinScreen;