import { FunctionComponent, useEffect, useState } from "react";
import ModalBase from "../../../../../../../../Common/ModalBase/ModalBase";
import NumberInput from "../../../../../../../../Common/NumberInput/NumberInput";
import TicketEditor from "./components/TicketEditor/TicketEditor";
import TicketNumber from "./components/TicketNumber/TicketNumber";

interface BuyTicketsModalProps {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void
}

const BuyTicketsModal: FunctionComponent<BuyTicketsModalProps> = ({ isOpen, setIsOpen }) => {

    const [title, setTitle] = useState<string>('Buy tickets');
    const [numberOfTickets, setNumberOfTickets] = useState<number>(0);
    const [totalCost, setTotalCost] = useState<number>(0)

    const [editNumbers, setEditNumbers] = useState<boolean>(false);

    useEffect(()=>{
        if(editNumbers){
            setTitle("Edit numbers")
        }
    }, [editNumbers])

    useEffect(()=>{
        if(isOpen){
            setEditNumbers(false);
        }
    }, [isOpen])


    return (<ModalBase
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={title}
        style={editNumbers ? {width: "50vw", maxWidth: "800px"} : {}}
    >

        {
            !editNumbers && <TicketNumber
                numberOfTickets={numberOfTickets}
                setNumberOfTickets={setNumberOfTickets}
                setTotalCost={setTotalCost}
                setNextStep={setEditNumbers}
            />
        }


        {
            editNumbers && <TicketEditor 
                numberOfTickets={numberOfTickets}
                totalCost={totalCost}
                setIsOpen={setIsOpen}
                setNextStep={setEditNumbers}
            />
        }
    </ModalBase>);
}

export default BuyTicketsModal;