import { FunctionComponent } from "react";

import Ball from './images/Ball.png'
import Logo from './images/Logo.png';

import classes from './TitleSection.module.scss';


interface TitleSectionProps {
    title?: string
}

const TitleSection: FunctionComponent<TitleSectionProps> = ({ title }) => {
    return (<section className={classes.TitleSection}>
        <div className={classes.titleSectionOutline}></div>
        <div className={classes.innerTitleSection}>
            <img src={Ball} alt='3d ball' className={classes.tdBall} />
            <img src={Logo} alt='3d PEAKDEFI logo' className={classes.tdLogo} />
            <div className={classes.centralSection}>
                <h1>{title}</h1>
            </div>
        </div>
    </section>);
}

export default TitleSection;