import { FunctionComponent, useEffect, useState } from "react";
import ButtonOrange from "../../../../../../../../../../Common/ButtonOrange/ButtonOrange";
import NumberInput from "../../../../../../../../../../Common/NumberInput/NumberInput";

import classes from './TicketNumber.module.scss'

import { useWeb3React } from '@web3-react/core'
import { injected } from '../../../../../../../../../../../connector'
import { useSelector } from 'react-redux';

interface TicketNumberProps {
    numberOfTickets: number,
    setNumberOfTickets: (amont: number) => void,
    setTotalCost: (amont: number) => void,
    setNextStep: (value: boolean)=>void
}

const TicketNumber: FunctionComponent<TicketNumberProps> = ({ numberOfTickets, setNumberOfTickets, setTotalCost, setNextStep }) => {
    const { activate, deactivate, account } = useWeb3React();

    const [cost, setCost] = useState<number>(0);

    const [bulkDiscout, setBulkDiscount] = useState<number>(0)
    const [bulkDiscoutPercent, setBulkDiscountPercent] = useState<number>(0)
    const [totalSum, setTotalSum] = useState<number>(0)



    //TO DO: replace with useState hook and fetch data from wallet provider
    const balance = useSelector((state: any)=>state.userWallet.balance)
    const decimals = useSelector((state: any) => state.userWallet.decimal);
    const peakBalance = useSelector((state: any) => (balance / Math.pow(10, decimals)));
    const options: number[] = useSelector((state: any) => state.lotteryInfo.lottery.backetNumber);
    const lottery = useSelector((state: any) => state.lotteryInfo.lottery);
    const prices: number[] = useSelector((state: any) => state.lotteryInfo.lottery.backetPrice);
    const ticketPrice = useSelector((state: any) => state.lotteryInfo.lottery.priceTicketInPeak);

    useEffect(() => {
        setCost(prices[options.indexOf(numberOfTickets)]);

        
        setBulkDiscount(prices[0] * numberOfTickets -  prices[options.indexOf(numberOfTickets)])
        setTotalCost(prices[options.indexOf(numberOfTickets)]);
        setTotalSum(prices[0] * numberOfTickets);
        setBulkDiscountPercent(100 - Math.floor( (prices[options.indexOf(numberOfTickets)]) / (prices[0] * numberOfTickets )* 100 ))
    }, [numberOfTickets]);


    //TO DO: write handlers
    const handleConnect = () => {
        activate(injected)
    }

    const handleEnterTicketNumbers = () => {
        setNextStep(true);
    }


    return (<div className={classes.TicketNumber}>
        <h1>Total Number of Tickets:</h1>
        
        {account && <>
            


            <div className={classes.options}>

                {
                    options.map(option =>
                        <div
                            className={numberOfTickets === option ? classes.selectedOption : classes.option}
                            onClick={() => {
                                setNumberOfTickets(option)
                            }}
                        >
                            <div className={classes.value}>{option}</div>
                        </div>
                    )
                }

            </div>
        </>}

        <NumberInput
            disable={true}
            value={numberOfTickets}
            setValue={setNumberOfTickets}
            subvalueConvertor={(value) => totalSum}
            subvalueTitle={'PEAK'}
        />
        {account && <>
        <p className={classes.peakBalance}>PEAK BALANCE: {peakBalance.toFixed(2)}</p>
        </>}
        <div className={classes.bill}>
            {/* <div className={classes.row}>
                <div className={classes.name}>Cost (PEAK)</div>
                <div className={classes.dots}></div>
                <div className={classes.value}>{cost} PEAK</div>
            </div> */}

            <div className={classes.row}>
                <div className={classes.name}>Discount</div>
                <div className={classes.dots}></div>
                <div className={classes.value}>~{isNaN(bulkDiscout) ? 0 : bulkDiscout} (~{isNaN(bulkDiscoutPercent) ? 0 : bulkDiscoutPercent}%) PEAK</div>
            </div>

            <div className={classes.totalRow}>

                <div className={classes.name}>You Pay</div>
                <div className={classes.dots}></div>
                <div className={classes.value}><b>{cost} PEAK</b></div>

            </div>
        </div>

        {
            !account &&
            <ButtonOrange className={classes.button} onClick={handleConnect}>
                Connect Wallet
            </ButtonOrange>
        }

        {
            account &&
            <ButtonOrange className={classes.button} onClick={handleEnterTicketNumbers}>
                Enter Ticket Numbers
            </ButtonOrange>
        }

    </div>);
}

export default TicketNumber;