import { FunctionComponent } from "react";
import BuyInformationSection from "./components/BuyInformationSection/BuyInformationSection";
import TitleSection from "./components/TitleSection/TitleSection";

import classes from './TopBlock.module.scss';

interface TopBlockProps {
    
}
 
const TopBlock: FunctionComponent<TopBlockProps> = () => {
    return (<div className={classes.TopBlock}>
        <TitleSection />
        <BuyInformationSection />
    </div>);
}
 
export default TopBlock;