import { FunctionComponent } from "react";
import ConnectWallet from "./components/ConnectWallet/ConnectWallet";
import FinishedRoundsAndHistory from "./components/FinishedRoundsAndHistory/FinishedRoundsAndHistory";
import HowToPlay from "./components/GeneralInfo/components/HowToPlay/HowToPlay";
import GeneralInfo from "./components/GeneralInfo/GeneralInfo";
import TopBlock from "./components/TopBlock/TopBlock";
import Referral from "./components/Referral/Referral";
import { useState, useEffect } from 'react'
import { ethers, BigNumber, Contract } from 'ethers';
import { abi as tokenAbi, tokenContractAddress } from './source/tokenAbi';
import { abi as referralAbi, referralContractAddress } from './source/referralAbi';
import { abi as lotteryAbi, lotteryContractAddress } from './source/lotteryAbi';
import { rpcWalletConnectProvider } from '../../../consts/walletConnect';
import { useWeb3React } from '@web3-react/core'
import store from "../../../app/store";
import { setBalance, setDecimal, setAddress, setReferralReward, setReferrerWallet } from '../../../features/userWalletSlice';
import { setLotteryContract, setTokenContract, setReferralContract } from '../../../features/contractsSlice';
import {
    setTimeEnd, setPrizePeak, setPrizeUSD, setCurrentIndex,
    setUserSelectIndex, setPeakPrice, setLottery, setMaxRoundsHistory
} from '../../../features/lotteryInfoSlice';
import { setSelectedLottery } from '../../../features/selectedLotterySlice';
import { useDispatch, useSelector } from 'react-redux';

import { getPrice } from "../../../API/tokenPrice"

import { providers } from "ethers";
import classes from './MainPage.module.scss'
import BuyTicketsModal from "./components/TopBlock/components/TitleSection/components/BuyTicketsModal/BuyTicketsModal";
import { setShowBuyTicketModal } from "../../../features/modalsSlice";
import RefereesTable from "./components/RefereesTable/RefereesTable";
import { useParams, useSearchParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
import QnA from "./components/QnA/QnA";
import ButtonBlue from "../../Common/ButtonBlue/ButtonBlue";
import { injected } from "../../../connector";
interface MainPageProps {

}

const MainPage: FunctionComponent<MainPageProps> = () => {
    const { activate, deactivate, account, error } = useWeb3React();

    //for fetching referrer address and saving it to cookies
    const [searchParams, setSearchParams] = useSearchParams();
    const [cookies, setCookie, removeCookie] = useCookies(['referrer_wallet_address'])

    const dispatch = useDispatch();

    const [showRefereesTable, setShowRefereesTable] = useState<boolean>(true);


    // store.dispatch(setAddress(account));

    const [tokenContract, setTokenContractState] = useState<Contract | null>(null);
    const [lotteryContract, setLotteryContractState] = useState<Contract | null>(null);
    const [referralContract, setReferralContractState] = useState<Contract | null>(null);

    async function fetchContractData() {
        const { ethereum } = window;
        let provider;
        let asign;
        if (ethereum && ethereum.networkVersion == 56 ) {
            provider = new ethers.providers.Web3Provider(ethereum)
            const signer = await provider.getSigner();
            asign = signer;
        } else {
            provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_PROVIDER);
            asign = provider
        }
        

        let TokenContract = new ethers.Contract(tokenContractAddress, tokenAbi, asign);
        let LotteryContract = new ethers.Contract(lotteryContractAddress, lotteryAbi, asign);
        let ReferralContract = new ethers.Contract(referralContractAddress, referralAbi, asign);
        let peakPrice = await getPrice().then((response: any) => { return response?.data?.price })
        dispatch(setPeakPrice(peakPrice))
        dispatch(setTokenContract(TokenContract))
        dispatch(setLotteryContract(LotteryContract))

        TokenContract.decimals().then((response: any) => {
            dispatch(setDecimal(response));
        })

        if (account) {
            TokenContract.balanceOf(account).then((response: any) => {
                dispatch(setBalance(response));
            })
            LotteryContract._users(account).then((response: any) => {
                dispatch(setReferralReward(response.referralRewards.toNumber() / Math.pow(10, 8)));
                dispatch(setReferrerWallet(response.referrer))
            })
        }
        setTokenContractState(TokenContract)
        setLotteryContractState(LotteryContract)
        setReferralContractState(ReferralContract)

        const currentIndex = await LotteryContract.viewCurrentLotteryId()
        dispatch(setCurrentIndex(currentIndex))
        const lotteryInformation = await LotteryContract.viewLottery(currentIndex.toNumber());
        dispatch(setPrizePeak(Math.round(lotteryInformation.amountCollectedInPeak / Math.pow(10, 8))))
        dispatch(setPrizeUSD(Math.round(lotteryInformation.amountCollectedInPeak * peakPrice / Math.pow(10, 8))))
        dispatch(setTimeEnd(lotteryInformation.endTime.toNumber()))
        dispatch(setLottery({
            amountCollectedInPeak: lotteryInformation.amountCollectedInPeak / Math.pow(10, 8),
            backetNumber: lotteryInformation.backetNumber,
            backetPrice: lotteryInformation.backetPrice.map((price: BigNumber) => { return price.toNumber() }),
            countWinnersPerBracket: lotteryInformation.countWinnersPerBracket.map((price: BigNumber) => { return price.toNumber() }),
            endTime: lotteryInformation.endTime.toNumber(),
            finalNumber: lotteryInformation.finalNumber,
            peakPerBracket: lotteryInformation.peakPerBracket.map((price: BigNumber) => {
                try {
                    return price.toNumber()
                } catch (error) {
                    return price
                }
                
            }),
            priceTicketInPeak: lotteryInformation.priceTicketInCake, //TODO Change to Peak
            rewardsBreakdown: lotteryInformation.rewardsBreakdown.map((price: BigNumber) => { return price.toNumber() / 100 }),
            startTime: lotteryInformation.startTime,
            status: lotteryInformation.status,
            treasuryFee: 0.05
        }))
        let parseIndex = currentIndex.toNumber()

        if (lotteryInformation.status == 3)
            parseIndex += 1

        if (parseIndex > 1) {
            dispatch(setUserSelectIndex(parseIndex - 1))
            dispatch(setMaxRoundsHistory(parseIndex))
            const lotteryInformationHistory = await LotteryContract.viewLottery(parseIndex - 1);
            dispatch(setSelectedLottery({
                amountCollectedInPeak: lotteryInformationHistory.amountCollectedInPeak / Math.pow(10, 8),
                backetNumber: lotteryInformationHistory.backetNumber,
                backetPrice: lotteryInformationHistory.backetPrice.map((price: BigNumber) => { return price.toNumber() }),
                countWinnersPerBracket: lotteryInformationHistory.countWinnersPerBracket.map((price: BigNumber) => { return price.toNumber() }),
                endTime: lotteryInformationHistory.endTime.toNumber(),
                finalNumber: lotteryInformationHistory.finalNumber,
                peakPerBracket: lotteryInformationHistory.peakPerBracket.map((price: number) => { return price / Math.pow(10, 8) }),
                priceTicketInPeak: lotteryInformationHistory.priceTicketInCake, //TODO Change to Peak
                rewardsBreakdown: lotteryInformationHistory.rewardsBreakdown.map((price: BigNumber) => { return price.toNumber() / 100 }),
                startTime: lotteryInformationHistory.startTime.toNumber(),
                status: lotteryInformationHistory.status,
                treasuryFee: 0.05
            }))
        }

    }

    useEffect(() => {
        fetchContractData()
    }, []);

    useEffect(() => {

        //saving referrer address
        const referrerAddress = searchParams.get('referrer_wallet_address');
        if (!!referrerAddress) {
            setCookie(
                'referrer_wallet_address',
                referrerAddress,
                {
                    expires: new Date(new Date().setMonth(new Date().getMonth() + 1))
                }
            );
        }

    }, [searchParams])

    async function updateOnWalletChange() {
        const { ethereum } = window;
        let provider;
        if (ethereum && ethereum.networkVersion == 56) {
            provider = new ethers.providers.Web3Provider(ethereum)
            const signer = await provider.getSigner();
            let TokenContract = new ethers.Contract(tokenContractAddress, tokenAbi, signer);
            let LotteryContract = new ethers.Contract(lotteryContractAddress, lotteryAbi, signer);
            let ReferralContract = new ethers.Contract(referralContractAddress, referralAbi, signer);
            dispatch(setTokenContract(TokenContract))
            dispatch(setLotteryContract(LotteryContract))
            dispatch(setReferralContract(ReferralContract))
        }
        tokenContract?.balanceOf(account).then((response: any) => {
            dispatch(setBalance(response));
        })
        lotteryContract?._users(account).then((response: any) => {
            referralContract?.user(account).then((response1: any) => {
                dispatch(setReferralReward((response.referralRewards.toNumber()- response1.toNumber())/ Math.pow(10, 8)));
            })
            dispatch(setReferrerWallet(response.referrer))
            })
    }

    useEffect(() => {
        updateOnWalletChange()
    }, [account]);

    const connectWallet = (): boolean => {
        activate(injected)
        return true;
    }

    return (<div className={classes.MainPage}>
        <TopBlock />
        <ConnectWallet />
        <Referral setShowRefereesTable={setShowRefereesTable} showRefereesTable={showRefereesTable} />
        {showRefereesTable && <RefereesTable />}
        <FinishedRoundsAndHistory />
        <GeneralInfo />
        <hr />
        <div style={{display:'flex', justifyContent:'center'}}>
            {
                account ?
                    <ButtonBlue onClick={() => dispatch(setShowBuyTicketModal(true))}>
                        Buy Tickets
                    </ButtonBlue>
                    :
                    <ButtonBlue onClick={() => connectWallet()}>
                        Connect Wallet
                    </ButtonBlue>
            }
        </div>
        <QnA />


    </div>);
}

export default MainPage;