import { Routes, Route } from 'react-router-dom';
import './App.css';
import BaseLayout from './components/Layout/BaseLayout/BaseLayout';
import MainPage from './components/Pages/MainPage/MainPage';
import './fonts.css';
import routes from './routes';
import { Provider } from 'react-redux';
import store from './app/store'
import { Web3ReactProvider } from '@web3-react/core';
import { ethers } from 'ethers';
import { ToastContainer, Flip } from 'react-toastify';
import { CookiesProvider } from 'react-cookie';
import 'react-toastify/dist/ReactToastify.css';
import 'font-awesome/css/font-awesome.min.css';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';

const POLLING_INTERVAL = 12000;

const getLibrary = (provider: any) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = POLLING_INTERVAL;
  return library;
};

function App() {
  return (
    <div>
      <CookiesProvider>
        <Web3ReactProvider getLibrary={getLibrary}>
          <Provider store={store}>
            <BaseLayout>
              <Routes>
                {
                  routes.map(route => <Route path={route.path} element={route.element} />)
                }
              </Routes>
            </BaseLayout>
          </Provider>
        </Web3ReactProvider>
      </CookiesProvider>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={'light'}
        transition={Flip}
      />
    </div>
  );
}

export default App;
