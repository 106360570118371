import React from "react"
import ContentLoader from "react-content-loader"

const SingleDigitLoader = (props: any) => (
    <ContentLoader
        speed={2}
        backgroundColor="rgb(0,0,0)"
        foregroundColor="rgb(0,0,0)"
        backgroundOpacity={0.05}
        foregroundOpacity={0.1}
        height={"2em"}
        width={"1.5em"}

        {...props}
    >
        <rect x="0" y="0.5em" rx="6px"  width="1.5em" height="1.5em" />
    </ContentLoader>
)

export default SingleDigitLoader;