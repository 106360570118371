import { createSlice } from "@reduxjs/toolkit";

export const modalsSlice = createSlice({
    name: 'wallet',
    initialState: {
        showBuyTicketModal: false
    }, 

    reducers: {
        setShowBuyTicketModal: (state, action)=> {
            return {...state, showBuyTicketModal: action.payload}
        },
        
    },
});

export const {setShowBuyTicketModal} = modalsSlice.actions;

export default modalsSlice.reducer;