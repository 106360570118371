import { FunctionComponent, ReactNode } from "react";
import TitleSection from "./components/TitleSection/TitleSection";

import classes from './GenericPage.module.scss'

interface GenericPageProps {
    title?:string, 
    children?: ReactNode
}
 
const GenericPage: FunctionComponent<GenericPageProps> = ({title, children}) => {
    return (<div className={classes.GenericPage}>
        <TitleSection title={title}/>
        {children}
    </div>);
}
 
export default GenericPage;