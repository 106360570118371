import { FunctionComponent } from "react";

import classes from './NumberInput.module.scss'

interface NumberInputProps {
    value: number,
    setValue: (value: number) => void,
    subvalueConvertor?: (value: number) => number,
    subvalueTitle?: string,
    disable: boolean
}

const NumberInput: FunctionComponent<NumberInputProps> = ({ value, setValue, subvalueConvertor, subvalueTitle, disable=false }) => {

    return (<div className={classes.NumberInput}>
        <div>
            <input disabled={disable} type="number" value={value} onChange={(e) => { setValue(parseInt(e.target.value)) }} />
            {
                !!subvalueConvertor &&
                <p>{subvalueConvertor(value)} {subvalueTitle}</p>
            }
        </div>

    </div>);
}

export default NumberInput;