import { FunctionComponent, useState } from "react";
import ButtonOrange from "../../../../../../../../Common/ButtonOrange/ButtonOrange";
import BuyTicketsModal from "../BuyTicketsModal/BuyTicketsModal";
import { useDispatch, useSelector } from 'react-redux';

import classes from './BuyTickets.module.scss';
import { setShowBuyTicketModal } from "../../../../../../../../../features/modalsSlice";
import { injected } from '../../../../../../../../../connector'
import { useWeb3React } from '@web3-react/core'
interface BuyTicketsProps {

}

const BuyTickets: FunctionComponent<BuyTicketsProps> = () => {
    const PrizeUSD = useSelector((state: any) => state.lotteryInfo.prizeUSD);
    const dispatch = useDispatch();
    const timeEnd = useSelector((state: any) => state.lotteryInfo.timeEnd);

    const { activate, deactivate, account } = useWeb3React();

    const connectWallet = (): boolean => {
        activate(injected)
        return true;
    }

    return (<div className={classes.BuyTickets}>
        <div className={classes.content}>
            <div>

                <h1 className={classes.prizes}>${PrizeUSD}</h1>
                <h2 className={classes.prizesSubtitle}>in prizes</h2>

            </div>
            { account ? 
              Date.now()/1000 < timeEnd &&
                    <ButtonOrange onClick={() => { dispatch(setShowBuyTicketModal(true)) }}>
                        Buy Tickets
                    </ButtonOrange>
                :
                    <ButtonOrange onClick={() => { connectWallet() }}>
                        Connect Wallet
                    </ButtonOrange>
            }

        </div>

    </div>);
}

export default BuyTickets;