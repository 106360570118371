import { FunctionComponent } from "react";
import GenericPage from "../../Common/GenericPage/GenericPage";

import classes from './TermsAndConditions.module.scss';

interface TermsAndConditionsProps {

}

const TermsAndConditions: FunctionComponent<TermsAndConditionsProps> = () => {
    return (
        <GenericPage title="Terms and conditions">
            <div className={classes.TermsAndConditions}>
                
            </div>
        </GenericPage>
    );
}

export default TermsAndConditions;