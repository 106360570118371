import {FunctionComponent, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowBuyTicketModal } from "../../../features/modalsSlice";
import BuyTicketsModal from "../../Pages/MainPage/components/TopBlock/components/TitleSection/components/BuyTicketsModal/BuyTicketsModal";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

import classes from './BaseLayout.module.scss'

interface BaseLayoutProps {
    children?: ReactNode
}
 
const BaseLayout: FunctionComponent<BaseLayoutProps> = ({children}) => {
    const dispatch = useDispatch();
    const isBuyTicketsModalOpen = useSelector((state:any)=>state.modals.showBuyTicketModal);
    return (<div className={classes.BaseLayout}>
        <Header />
        {children}
        <Footer />
        <BuyTicketsModal 
            isOpen={isBuyTicketsModalOpen}
            setIsOpen={(isOpen: boolean)=>dispatch(setShowBuyTicketModal(isOpen))}
        />
    </div>);
}
 
export default BaseLayout;