import { FunctionComponent } from "react";
import { useSelector } from "react-redux";

import classes from './RoundControls.module.scss';

import ArrowLeft from './images/ArrowLeft.svg'
import ArrowRight from './images/ArrowRight.svg';
import ArrowEnd from './images/ArrowEnd.svg';

interface RoundControlsProps {
    currentRound: number,
    drawDate: Date,
    setCurrentRound: (roundNumber: number) => void
}
 
const RoundControls: FunctionComponent<RoundControlsProps> = ({ currentRound, drawDate, setCurrentRound }) => {
    
    const maxRound = useSelector((state: any) => state.lotteryInfo.maxRoundsHistory)

    return (<div className={classes.RoundControls}>
        <div className={classes.roundInfo}>
            <h1>Round</h1>
            <h2>Drawn {drawDate.toLocaleString('default', { month: 'short' })} {drawDate.getDate()}, {drawDate.getFullYear()}, {drawDate.toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'})}</h2>
        </div>

        <div className={classes.roundNumber}>
            {currentRound}
        </div>

        <div className={classes.controls}>
            <button disabled={currentRound===1} onClick={() => { setCurrentRound(currentRound-1) }} >
                <img src={ArrowLeft} alt="Arrow Left"/>
            </button>

            <button disabled={currentRound >= maxRound - 1} onClick={() => { setCurrentRound(currentRound+1) }} >
                <img src={ArrowRight} alt="Arrow Right"/>
            </button>

            <button disabled={currentRound >= maxRound - 1}  onClick={() => { setCurrentRound(maxRound - 1)}}>
                <img src={ArrowEnd} alt="Arrow End"/>
            </button>
        </div>
    </div>);
}
 
export default RoundControls;