import { FunctionComponent } from "react";

import TornTicketAndBalls from './../../images/TornTicketAndBalls.svg';

import classes from './LoseScreen.module.scss'

interface LoseScreenProps {

}

const LoseScreen: FunctionComponent<LoseScreenProps> = () => {
    return (<div className={classes.LoseScreen}>
        <div className={classes.textSection}>
            <h1>There’re no prizes to collect :(  Better luck next time!</h1>
        </div>
        <img src={TornTicketAndBalls} alt='Ticket with PEAKDEFI logo' />
    </div>);
}

export default LoseScreen;