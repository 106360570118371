import { FunctionComponent, useEffect, useState } from "react";

import Logo from './images/Logo.svg'
import UpButton from './images/UpButton.svg';
import Telegram from './images/Telegram.svg';
import Discord from './images/Discord.svg';
import Twitter from './images/Twitter.svg';
import Medium from './images/Medium.svg';
import Github from './images/Github.svg';
import Defipulse from './images/Defipulse.svg'

import classes from './Footer.module.scss'
import ExchangersPopup from "./components/ExchangersPopup/ExchangersPopup";
import { getPrice } from "../../../API/tokenPrice";

interface FooterProps {

}

interface ILink {
    text: string,
    link: string
};

interface ILinks {
    category: string,
    links: Array<ILink>
};

interface ISocialLink {
    icon: string,
    link: string
}

const Footer: FunctionComponent<FooterProps> = () => {

    const [showExchangersModal, setShowExchangersModal] = useState<boolean>(false);

    const links: ILinks[] = [
        
    ]

    const socials: ISocialLink[] = [
        { icon: Telegram, link: "https://t.me/peakdefi_official" },
        { icon: Discord, link: '' },
        { icon: Twitter, link: "https://twitter.com/peakdefi" },
        { icon: Medium, link: "https://medium.com/@PeakDeFi" },
        { icon: Github, link: "https://github.com/peakdefi" },
        { icon: Defipulse, link: "https://defipulse.com/" }
    ]

    const [PEAKprice, setPeakPrice] = useState<number>(0.25);

    useEffect(()=>{
        getPrice().then((response: any)=>{
            setPeakPrice(parseFloat(response.data.price.toFixed(3)));
        })
    }, [])

    return (<footer className={classes.Footer}>
        <div className={classes.innerFooter}>
            <div className={classes.topPart}>
                <div className={classes.logoAndPrice}>
                    <img src={Logo} alt="peakdefi logo" className={classes.logo} />
                    <div className={classes.price} onClick={() => setShowExchangersModal(true)}>
                        <h1>~{PEAKprice}$</h1>
                        <div className={classes.verticalBar}></div>
                        <h2>Buy PEAK</h2>
                    </div>
                </div>

                <div className={classes.linkGroups}>
                    {
                        links.map(linkGroup => <div className={classes.linkGroup}>
                            <h1>{linkGroup.category}</h1>
                            {linkGroup.links.map(link => <a href={link.link}>
                                {link.text}
                            </a>)}
                        </div>)
                    }
                </div>

                <button className={classes.upButton}>
                    <img style={{cursor:"pointer"}} src={UpButton}  onClick={() =>{window.scrollTo(0, 0)}} alt='up button' />
                </button>

            </div>

            <hr />

            <div className={classes.text}>
                IMPORTANT DISCLAIMER: The PEAKDEFI lottery is launched by the PEAKDEFI community as an 
                experimental project. Please be aware that any interaction with the platform, especially 
                any token deposit, is at your own risk. Due to the smart contract architecture, using 
                this platform comes with high risks and also potential high rewards. Be sure that you 
                fully understand what you are doing before making any deposits. You are solely responsible 
                for your funds.
            </div>

            <hr />

            <div className={classes.bottomPart}>
                <div className={classes.peakLabs}></div>
                <div className={classes.socials}>
                    {
                        socials.map(e => <a href={e.link}>
                            <img src={e.icon} alt='' />
                        </a>)
                    }
                </div>
            </div>
        </div>


        <ExchangersPopup isOpen={showExchangersModal} setIsOpen={setShowExchangersModal} />
    </footer>);
}

export default Footer;