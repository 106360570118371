import { FunctionComponent, useState } from "react";

import classes from './DetailsSection.module.scss';
import { useSelector } from 'react-redux';
import MatchLoader from "../../../../../../../../Common/ContentLoaders/MatchLoader/MatchLoader";
import PrizePotLoader from "../../../../../../../../Common/ContentLoaders/PrizePotLoader/PrizePotLoader";
import SingleDigitLoader from "../../../../../../../../Common/ContentLoaders/SingleDigitLoader/SingleDigitLoader";

interface DetailsSectionProps {
    isLoading?: boolean
}

interface IRoundMatch {
    name: string,
    amountInPeak: string,
    amountInUSD: string,
    singleTicketPeak?: string,
    winningTicketsCount?: number
}
function numFormatter(num: any) {
    try {
        if(num > 999 && num < 1000000){
        return (num/1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
        }
        else if(num > 1000000 && num < 10**9){
            return (num/1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
        }
        else if(num > 10**9){
            return((num/(10**9)).toFixed(2)+'B');
        }
        else{
            return parseFloat(num).toFixed(2); // if value < 1000, nothing to do
        }
    } catch (error) {
        
    }
    
}

const DetailsSection: FunctionComponent<DetailsSectionProps> = ({ isLoading }) => {

    const peakPrice = useSelector((state: any) => state.lotteryInfo.peakPrice);
    const PrizeUSD = useSelector((state: any) => state.selectedLotterySlice.selectedLottery.amountCollectedInPeak * peakPrice);
    const PrizePeak = useSelector((state: any) => state.selectedLotterySlice.selectedLottery.amountCollectedInPeak);
    const selectedLottery = useSelector((state: any) => state.selectedLotterySlice.selectedLottery)

    const treasuryFee = useSelector((state: any) => state.selectedLotterySlice.selectedLottery.treasuryFee);

    const PeakTreasury = PrizePeak * treasuryFee;
    const USDTreasury = PrizeUSD * treasuryFee;

    const prizeWinPeak = (index: number): string => {
        if (index == 6)
            return PeakTreasury.toFixed(2)

        return parseFloat(((PrizePeak ) * selectedLottery.rewardsBreakdown[index]).toString()).toFixed(2)
    }

    const prizeWinUSD = (index: number): string => {
        if (index == 6)
            return USDTreasury.toFixed(2)
        return Math.abs(parseFloat(((PrizeUSD) * selectedLottery.rewardsBreakdown[index]).toString())).toFixed(2)
    }

    const singleTicketPeak = (index: number): string => {
        if (selectedLottery.countWinnersPerBracket[index])
            return (parseFloat(prizeWinPeak(index)) / selectedLottery.countWinnersPerBracket[index]).toFixed(2)
        return "0.00"
    }

    const [matches, setMatches] = useState<IRoundMatch[]>([
        { name: 'Match first 1', amountInPeak: prizeWinPeak(0), amountInUSD: prizeWinUSD(0), singleTicketPeak: singleTicketPeak(0), winningTicketsCount: selectedLottery.countWinnersPerBracket[0] },
        { name: 'Match first 2', amountInPeak: prizeWinPeak(1), amountInUSD: prizeWinUSD(1), singleTicketPeak: singleTicketPeak(0), winningTicketsCount: selectedLottery.countWinnersPerBracket[1] },
        { name: 'Match first 3', amountInPeak: prizeWinPeak(2), amountInUSD: prizeWinUSD(2), singleTicketPeak: singleTicketPeak(0), winningTicketsCount: selectedLottery.countWinnersPerBracket[2] },
        { name: 'Match first 4', amountInPeak: prizeWinPeak(3), amountInUSD: prizeWinUSD(3), singleTicketPeak: singleTicketPeak(0), winningTicketsCount: selectedLottery.countWinnersPerBracket[3] },
        { name: 'Match first 5', amountInPeak: prizeWinPeak(4), amountInUSD: prizeWinUSD(4), singleTicketPeak: singleTicketPeak(0), winningTicketsCount: selectedLottery.countWinnersPerBracket[4] },
        { name: 'Match all 6', amountInPeak: prizeWinPeak(5), amountInUSD: prizeWinUSD(5), singleTicketPeak: singleTicketPeak(0), winningTicketsCount: selectedLottery.countWinnersPerBracket[5] },
        { name: 'Burn', amountInPeak: PeakTreasury.toFixed(2), amountInUSD: USDTreasury.toFixed(2) }
    ]);

    return (<div className={classes.DetailsSection}>
        <section className={classes.upperInfoSection}>
            <div className={classes.column}>
                <h1>Prize pot</h1>
                {
                    !isLoading ? <div className={classes.prizePotInfo}>
                        <h1>${numFormatter(PrizeUSD.toFixed(2))}</h1>
                        <h2>{numFormatter(parseFloat(PrizePeak).toFixed(2))} PEAK</h2>
                    </div>
                        : <PrizePotLoader />
                }

            </div>

            <div className={classes.column}>
                <h1>Total winners this round: </h1>
                {isLoading && <SingleDigitLoader />}
                {!isLoading && <h1>{selectedLottery.countWinnersPerBracket.reduce((a: number, b: number) => a + b, 0)}</h1>}
            </div>
        </section>

        <div className={classes.dottedLine} />

        <section className={classes.bottomInfoSection}>
            <p>Match the winning numbers in the same order to win prizes. Current prizes:</p>

            <div className={classes.matches}>
                {
                    matches.map((match, index) => {
                        return isLoading ? <MatchLoader /> : <div className={classes.match}>
                            <div className={classes.innerMatch}>
                                <div className={classes.name}>{match.name}</div>
                                <div className={classes.dots}></div>
                                <div className={classes.prices}>
                                    <h1>{numFormatter(prizeWinPeak(index))} PEAK</h1>
                                    <h2>~${numFormatter(prizeWinUSD(index))}</h2>
                                </div>
                            </div>
                            <p>
                                {
                                    !!selectedLottery.countWinnersPerBracket[index] &&
                                    <>{numFormatter(singleTicketPeak(index))} PEAK each {selectedLottery.countWinnersPerBracket[index]} Winning Tickets</>
                                }
                            </p>
                        </div>
                    })
                }
            </div>
        </section>
    </div>);
}

export default DetailsSection;