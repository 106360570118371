import { FunctionComponent, useEffect, useState } from "react";
import ButtonOrange from "../../../../../../../../../../Common/ButtonOrange/ButtonOrange";
import { useSelector } from 'react-redux';
import { ethers, BigNumber } from 'ethers';
import { abi as lotteryAbi, lotteryContractAddress } from '../../../../../../../../source/lotteryAbi';
import { abi as tokenAbi, tokenContractAddress } from '../../../../../../../../source/tokenAbi';

import Ticket from './images/Ticket.svg'
import ArrowLeft from './images/ArrowLeft.svg'
import Regenerate from './images/Regenerate.svg'

import classes from './TicketEditor.module.scss'
import ButtonOrangeLight from "../../../../../../../../../../Common/ButtonOrangeLight/ButtonOrangeLight";
import { toast } from "react-toastify";
import { useWeb3React } from "@web3-react/core";
import { useCookies } from "react-cookie";
import { CircularProgress } from "@mui/material";

interface TicketEditorProps {
}

const TicketEditor: FunctionComponent<TicketEditorProps> = () => {

    const { account } = useWeb3React();

    const lotteryId = useSelector((state: any) => state.lotteryInfo.currentIndex);
    const [tickets, setTickets] = useState<number[][]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);


    useEffect(() => {
        setIsLoading(true);
        const innerAsync = async () => {
            const { ethereum } = window;
            let provider;
            let asign;
            if (ethereum && ethereum.networkVersion == 56) {
                provider = new ethers.providers.Web3Provider(ethereum)
                const signer = await provider.getSigner();
                asign = signer;
            } else {
                provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_PROVIDER);
                asign = provider
            }

            let LotteryContract = new ethers.Contract(lotteryContractAddress, lotteryAbi, asign);

            const response = await LotteryContract.viewUserInfoForLotteryId(account, lotteryId, 0, 1000000);
            let ticketNumbers = response[1].map((e: number) => e.toString()).map((e: string) => e.split(""));
            ticketNumbers = ticketNumbers.map((e: string[]) => e.slice(1));
            ticketNumbers = ticketNumbers.map((e: string[]) => {
                return e.map((e1: string) => parseInt(e1));
            })
            ticketNumbers = ticketNumbers.map((e: number[]) => e.reverse());
            setTickets([...ticketNumbers]);
            setIsLoading(false);

        }

        innerAsync();

    }, [account]);


    return (<div className={classes.TicketEditor}>

        {!isLoading &&
            <div className={classes.scrollableWrapper}>
                <div className={classes.ticketScrollable}>
                    <div className={tickets.length === 1 ? classes.singleTicketSection : classes.ticketSection}>
                        {
                            tickets.map((ticket, i) => <div className={classes.ticket}>
                                <h1>
                                    <img
                                        src={Ticket}
                                        alt='arrow'
                                    />
                                    Ticket {i + 1}
                                </h1>

                                <div className={classes.ticketNumbers}>
                                    {
                                        ticket.map((number, j) => {

                                            const handleEditNumber = (newValue: number): void => {
                                                let numbersCopy = JSON.parse(JSON.stringify(tickets));
                                                numbersCopy[i][j] = newValue >= 10 ? Math.floor(newValue / 10) : newValue;
                                                setTickets(numbersCopy);
                                            }

                                            return <div className={classes.number}>
                                                <input
                                                    type="number"
                                                    min='0'
                                                    max='9'
                                                    maxLength={1}
                                                    value={number}
                                                    onChange={(e) => handleEditNumber(parseInt(e.target.value))}
                                                    disabled={true} //TO DO: change to tickets.length>1
                                                />
                                            </div>
                                        }
                                        )
                                    }
                                </div>
                            </div>)
                        }
                    </div>
                </div>
            </div>
        }
        {
            isLoading &&
            <div className={classes.loader}>
                <CircularProgress />
            </div>

        }

    </div>);
}

export default TicketEditor;