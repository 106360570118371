import { FunctionComponent, useEffect, useState } from "react";
import ButtonBlue from "../../../../../../../../Common/ButtonBlue/ButtonBlue";

import Arrow from './images/Arrow.svg';

import classes from './BuyInfoCard.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import ContentLoader from "react-content-loader";
import MatchLoader from "../../../../../../../../Common/ContentLoaders/MatchLoader/MatchLoader";
import PrizePotLoader from "../../../../../../../../Common/ContentLoaders/PrizePotLoader/PrizePotLoader";
import { setShowBuyTicketModal } from "../../../../../../../../../features/modalsSlice";
import { injected } from '../../../../../../../../../connector'
import { useWeb3React } from '@web3-react/core'
import { lotteryContractAddress, abi as lotteryAbi } from "../../../../../../source/lotteryAbi";
import { ethers } from "ethers";
import MyTicketsModal from "../MyTicketsModal/MyTicketsModal";
import {BsArrowRight} from 'react-icons/bs';
import { setBoughtTickets } from "../../../../../../../../../features/userInfoSlice";

interface BuyInfoCardProps {

}

interface IMatches {
    name: string;
    amountInPeak: string;
    amountInUSD: string;
}

function numFormatter(num: any) {
    try {
        if(num > 999 && num < 1000000){
        return (num/1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
        }
        else if(num > 1000000 && num < 10**9){
            return (num/1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
        }
        else if(num > 10**9){
            return((num/(10**9)).toFixed(2)+'B');
        }
        else{
            return parseFloat(num).toFixed(2); // if value < 1000, nothing to do
        }
    } catch (error) {
    }
    
}

const BuyInfoCard: FunctionComponent<BuyInfoCardProps> = () => {

    const dispatch = useDispatch();
    const peakPrice = useSelector((state: any) => state.lotteryInfo.peakPrice);
    const PrizeUSD = useSelector((state: any) => state.lotteryInfo.lottery.amountCollectedInPeak * peakPrice);
    const PrizePeak = useSelector((state: any) => state.lotteryInfo.lottery.amountCollectedInPeak);
    const rewardsBreakdown = useSelector((state: any) => state.lotteryInfo.lottery.rewardsBreakdown);
    const treasuryFee = useSelector((state: any) => state.lotteryInfo.lottery.treasuryFee);
    const lotteryId = useSelector((state: any) => state.lotteryInfo.currentIndex);
    const boughtTicketsCount = useSelector((state: any)=>state.userInfo.boughtTickets);

    const PeakTreasury = PrizePeak * treasuryFee;
    const USDTreasury = PrizeUSD * treasuryFee;

    const [isSectionCollapsed, setIsSectionCollapsed] = useState<boolean>(true);
    const [openTicketViewer, setOpenTicketViewer] = useState<boolean>(false);
    

    const prizeWinPeak = (index: number): string => {
        if (index == 6)
            return PeakTreasury.toFixed(2)
        return parseFloat((PrizePeak * rewardsBreakdown[index]).toString()).toFixed(2)
    }

    const prizeWinUSD = (index: number): string => {
        if (index == 6)
            return USDTreasury.toFixed(2)
        return parseFloat((PrizeUSD * rewardsBreakdown[index]).toString()).toFixed(2)
    }

    const matchValues: IMatches[] = [
        { name: 'Match first 1', amountInPeak: prizeWinPeak(0), amountInUSD: prizeWinUSD(0) },
        { name: 'Match first 2', amountInPeak: prizeWinPeak(1), amountInUSD: prizeWinUSD(1) },
        { name: 'Match first 3', amountInPeak: prizeWinPeak(2), amountInUSD: prizeWinUSD(2) },
        { name: 'Match first 4', amountInPeak: prizeWinPeak(3), amountInUSD: prizeWinUSD(3) },
        { name: 'Match first 5', amountInPeak: prizeWinPeak(4), amountInUSD: prizeWinUSD(4) },
        { name: 'Match all 6', amountInPeak: prizeWinPeak(5), amountInUSD: prizeWinUSD(5) },
        { name: 'Burn', amountInPeak: PeakTreasury.toFixed(2), amountInUSD: USDTreasury.toFixed(2) }
    ]



    const numbersWithCommas = (num: any): string => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const { activate, deactivate, account } = useWeb3React();

    const connectWallet = (): boolean => {
        activate(injected)
        return true;
    }

    useEffect(() => {

        const innerAsync = async () => {
            const { ethereum } = window;
            let provider;
            let asign;
            if (ethereum && ethereum.networkVersion == 56) {
                provider = new ethers.providers.Web3Provider(ethereum)
                const signer = await provider.getSigner();
                asign = signer;
            } else {
                provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_PROVIDER);
                asign = provider
            }

            let LotteryContract = new ethers.Contract(lotteryContractAddress, lotteryAbi, asign);

            const response = await LotteryContract.viewUserInfoForLotteryId(account, lotteryId, 0, 1000000);
            dispatch(setBoughtTickets(response[1].length));
        }

        innerAsync();

    }, [account]);

    return (<div className={classes.BuyInfoCard}>
        <section className={classes.upperInfoSection}>
            <div className={classes.row}>
                <h1>Prize pot</h1>
                <div className={classes.prizePotAmount}>

                    <>
                        <h1>${numbersWithCommas(numFormatter(parseFloat(PrizeUSD.toFixed(2))))}</h1>
                        <h2>{numbersWithCommas(numFormatter(parseFloat(PrizePeak.toFixed(2))))} PEAK</h2>
                    </>
                </div>
            </div>


            <div className={classes.minirow}>
                <h1>Your tickets</h1>
                <h1 className={classes.ticketsCounter}>{boughtTicketsCount}</h1>
            </div>

            <div style={{flexDirection:"row-reverse"}} className={classes.minirow}>

                {
                    account ?
                        <ButtonBlue onClick={() => dispatch(setShowBuyTicketModal(true))} className={classes.buyConnect}>
                            { boughtTicketsCount > 0 ? "Buy more Tickets" : "Buy Tickets"}
                        </ButtonBlue>
                        :
                        <ButtonBlue onClick={() => connectWallet()} className={classes.buyConnect}>
                            Connect Wallet
                        </ButtonBlue>
                }

                {
                    account &&
                    <button
                        onClick={() => { setOpenTicketViewer(true) }}
                        className={classes.viewTickets}
                    >
                        View tickets <BsArrowRight className={classes.icon}/>
                    </button>
                }
            </div>

        </section>
        <hr />

        <section className={classes.collapsibleSectionWrapper} style={{ maxHeight: isSectionCollapsed ? '0' : '100vh' }}>
            <div className={classes.collapsibleSection}>
                <p>Match the winning numbers in the same order to win prizes. Current prizes:</p>

                <div className={classes.matches}>
                    {
                        matchValues.map((e, i) => {
                            return <div className={classes.match}>
                                <div className={classes.name}>{e.name}</div>
                                <div className={classes.dots}></div>
                                <div className={classes.prices}>
                                    <h1>{numFormatter(prizeWinPeak(i))} PEAK</h1>
                                    <h2>~${numFormatter(prizeWinUSD(i))}</h2>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </section>

        <section className={classes.collapseControls}>
            <div onClick={() => setIsSectionCollapsed(!isSectionCollapsed)}>Details <img src={Arrow} style={{ transform: isSectionCollapsed ? 'none' : 'rotate(180deg)' }} /></div>
        </section>

        <MyTicketsModal isOpen={openTicketViewer} setIsOpen={setOpenTicketViewer} />
    </div>);
}

export default BuyInfoCard;