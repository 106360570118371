import { FunctionComponent } from "react";
import classes from './PoppyTable.module.scss';
import { IColumn } from "../../../types/table";

interface PoppyTableProps {
    columns: IColumn[];
    rows: any[];
    className?: string;
}

const PoppyTable: FunctionComponent<PoppyTableProps> = (props) => {
    return (<div className={classes.PoppyTable}>
        <div className={classes.header}>
            {props.columns.map((column: IColumn) => <div key={column.key}>{column.title}</div>)}
        </div>
        <div className={classes.body}>
            {props.rows.map((row: any) => {
                return <div className={classes.row}>
                    {props.columns.map((column: IColumn) => <div>{row[column.key]}</div>)}
                </div>
            })}
        </div>
    </div>);
}

export default PoppyTable;