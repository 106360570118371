import { FunctionComponent, useEffect, useState } from "react";
import ButtonOrange from "../../../../../../../../../../Common/ButtonOrange/ButtonOrange";
import { useDispatch, useSelector } from 'react-redux';
import { ethers, BigNumber } from 'ethers';
import { abi as lotteryAbi, lotteryContractAddress } from '../../../../../../../../source/lotteryAbi';
import { abi as tokenAbi, tokenContractAddress } from '../../../../../../../../source/tokenAbi';

import Ticket from './images/Ticket.svg'
import ArrowLeft from './images/ArrowLeft.svg'
import Regenerate from './images/Regenerate.svg'

import classes from './TicketEditor.module.scss'
import ButtonOrangeLight from "../../../../../../../../../../Common/ButtonOrangeLight/ButtonOrangeLight";
import { toast } from "react-toastify";
import { useWeb3React } from "@web3-react/core";
import { useCookies } from "react-cookie";
import { setBoughtTickets } from "../../../../../../../../../../../features/userInfoSlice";
import { setBalance } from '../../../../../../../../../../../features/userWalletSlice';

interface TicketEditorProps {
    numberOfTickets: number,
    totalCost: number,
    setIsOpen: (isOpen: boolean) => void,
    setNextStep: (nextStep: boolean) => void
}

const TicketEditor: FunctionComponent<TicketEditorProps> = ({ numberOfTickets, totalCost, setIsOpen, setNextStep }) => {

    const { account } = useWeb3React();
    const dispatch = useDispatch();

    const boughtTicketsCount = useSelector((state: any)=>state.userInfo.boughtTickets);

    const lotteryId = useSelector((state: any) => state.lotteryInfo.currentIndex);
    const [tickets, setTickets] = useState<number[][]>(
        Array.from({ length: numberOfTickets }, () => Array.from({ length: 6 }, () => Math.floor(Math.random() * 10)))
    );

    const [cookies, setCookie] = useCookies(['referrer_wallet_address']);

    const [allowance, setAllowance] = useState<any>(0);

    useEffect(() => {

        const callback = async () => {
            const { ethereum } = window;
            if (ethereum) {
                const provider = new ethers.providers.Web3Provider(ethereum)
                const signer = await provider.getSigner();
                let contract = new ethers.Contract(tokenContractAddress, tokenAbi, signer);
                contract.allowance(account, lotteryContractAddress).then((response: any) => {
                    setAllowance(response);
                })
            }
        }

        callback();

    }, [account]);

    const buyTickets = async () => {
        const { ethereum } = window;
        if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum)
            const signer = await provider.getSigner();

            const referrer_wallet_address = cookies.referrer_wallet_address;

            let contract = new ethers.Contract(lotteryContractAddress, lotteryAbi, signer);

            const sendableTickets = tickets.map(num => {
                let find = [1].concat(num.reverse())//<===================REVERSING HERE
                return parseInt(find.join(''));
            })

            contract.buyTickets(lotteryId, sendableTickets, referrer_wallet_address ?? '0x0000000000000000000000000000000000000000')
                .then((response: any) => {
                    const transaction: Promise<any> = response.wait().then(() => { 
                        setNextStep(false); 
                        setIsOpen(false); 
                        dispatch(setBoughtTickets(boughtTicketsCount + sendableTickets.length))
                        let contract = new ethers.Contract(tokenContractAddress, tokenAbi, signer);
                        contract.balanceOf(account).then((response: any) => {
                            
                            dispatch(setBalance(response));
                        })
                    });
                    toast.promise(
                        transaction,
                        {
                            pending: 'Ticket purchase pending',
                            success: 'Congratulations! Your ticket purchase is complete',
                            error: "Purchase failed"
                        }
                    )
                })
                .catch((error: any) => {
                    toast.error(error.error.message.replace('execution reverted: ', ''));
                })
        } else {
            if (!ethereum)
                alert("Connect wallet")
        }
    }

    const approve = async () => {
        const { ethereum } = window;
        if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum)
            const signer = await provider.getSigner();
            let contract = new ethers.Contract(tokenContractAddress, tokenAbi, signer);
            try {
                const approvalRequest = await contract.approve(lotteryContractAddress, ethers.constants.MaxUint256);
                const transaction = approvalRequest.wait().then((response: any) => {
                    setAllowance(ethers.constants.MaxUint256);
                });

                toast.promise(
                    transaction,
                    {
                        pending: 'Approval transaction pending',
                        success: 'New amount successfully approved',
                        error: "Transaction failed"
                    }
                );
            } catch (error) {

            }
        }
    }

    const generateValues: () => void = () => {
        setTickets(Array.from({ length: numberOfTickets }, () => Array.from({ length: 6 }, () => Math.floor(Math.random() * 10))))
    }

    return (<div className={classes.TicketEditor}>

        <div className={classes.scrollableWrapper}>
            <div className={classes.ticketScrollable}>
                <div className={tickets.length === 1 ? classes.singleTicketSection : classes.ticketSection}>
                    {
                        tickets.map((ticket, i) => <div className={classes.ticket}>
                            <h1>
                                <img
                                    src={Ticket}
                                    alt='arrow'
                                />
                                Ticket {i + 1}
                            </h1>

                            <div className={classes.ticketNumbers}>
                                {
                                    ticket.map((number, j) => {

                                        const handleEditNumber = (newValue: number): void => {
                                            let numbersCopy = JSON.parse(JSON.stringify(tickets));
                                            numbersCopy[i][j] = newValue >= 10 ? Math.floor(newValue/10) : newValue;
                                            setTickets(numbersCopy);
                                        }

                                        return <div className={classes.number}>
                                            <input
                                                type="number"
                                                min='0'
                                                max='9'
                                                maxLength={1}
                                                value={number}
                                                onChange={(e) => handleEditNumber(parseInt(e.target.value))}
                                                disabled={false} //TO DO: change to tickets.length>1
                                            />
                                        </div>
                                    }
                                    )
                                }
                            </div>
                        </div>)
                    }
                </div>
            </div>
        </div>


        <footer>
            <div className={classes.totalCost}>
                <div className={classes.name}>Total costs</div>
                <div className={classes.dots} />
                <div className={classes.value}>{totalCost} PEAK</div>
            </div>

            <div className={classes.buttons}>
                <ButtonOrangeLight className={classes.button} onClick={generateValues}>
                    <img src={Regenerate} />Regenerate
                </ButtonOrangeLight>

                <ButtonOrange className={classes.button} onClick={() => { allowance > 0 ? buyTickets() : approve() }}>
                    {allowance > 0 ? 'Save Numbers and Buy' : "Approve"}
                </ButtonOrange>
            </div>


            {/*<button className={classes.goBackButton}>
                <img src={ArrowLeft} />
                Go Back
            </button>*/}
        </footer>


    </div>);
}

export default TicketEditor;