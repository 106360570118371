import { FunctionComponent } from "react";

import classes from './HowToPlay.module.scss';

import Step1 from './images/Step1.svg';
import Step2 from './images/Step2.svg';
import Step3 from './images/Step3.svg';

interface HowToPlayProps {

}

interface ISteps{
    icon: string,
    title: string,
    text: string
}

const HowToPlay: FunctionComponent<HowToPlayProps> = () => {

    const steps: ISteps[] =[
        {icon: Step1, title: 'Buy tickets', text: 'Prices are set when the round starts, equal to 3 USD in PEAK per ticket.'},
        {icon: Step2, title: 'Wait for the draw', text: 'There is one draw every Saturday at 10 AM CET.'},
        {icon: Step3, title: 'Check for Prizes', text: 'Once the round’s over, come back to the page and check to see if you’ve won!'}
    ]

    return (<section className={classes.HowToPlay}>
        <h1>How to Play</h1>
        <h2>
            If the digits on your tickets match the winning numbers in the correct order, 
            you win a portion of the prize pool. Simple!
        </h2>

        <div className={classes.steps}>
            {
                steps.map(step=><div className={classes.step}>
                    <img src={step.icon}/>
                    <h1>{step.title}</h1>
                    <h2>{step.text}</h2>
                </div>)
            }
        </div>
    </section>);
}

export default HowToPlay;