import { createSlice } from "@reduxjs/toolkit";

export const contractSlice = createSlice({
    name: 'wallet',
    initialState: {
        tokenContract: null,
        lotteryContract: null,
        referralContract: null,
    }, 

    reducers: {
        setTokenContract: (state, action)=> {
            if(action.payload===undefined)
                return {...state, tokenContract: null}
            return {...state, tokenContract: action.payload}
        },
        setLotteryContract: (state, action)=> {
            if(action.payload===undefined)
                return {...state, lotteryContract: null}
            return {...state, lotteryContract: action.payload}
        },
        setReferralContract: (state, action)=> {
            if(action.payload===undefined)
                return {...state, referralContract: null}
            return {...state, referralContract: action.payload}
        },
    },
});

export const {setTokenContract, setLotteryContract, setReferralContract} = contractSlice.actions;

export default contractSlice.reducer;