import { FunctionComponent, ReactNode } from "react";

import classes from './ButtonOrangeLight.module.scss';

interface ButtonOrangeLightProps {
    onClick?: () => boolean | void,
    className?: string,
    disabled?: boolean,
    children: ReactNode
}

const ButtonOrangeLight: FunctionComponent<ButtonOrangeLightProps> = ({ onClick, children, className, disabled }) => {
    return (<button onClick={onClick} className={classes.ButtonOrange + ' ' + className} disabled={!!disabled}>
        {children}
    </button>);
}

export default ButtonOrangeLight;