import { FunctionComponent } from "react";
import HowToPlay from "./components/HowToPlay/HowToPlay";
import PrizeFunds from "./components/PrizeFunds/PrizeFunds";
import WinningCriteria from "./components/WinningCriteria/WinningCriteria";

import classes from './GeneralInfo.module.scss';

interface GeneralInfoProps {

}

const GeneralInfo: FunctionComponent<GeneralInfoProps> = () => {
    return (<div className={classes.GeneralInfo}>
        <HowToPlay />
        <div className={classes.line}></div>
        <WinningCriteria />
        <div className={classes.line}></div>
        <PrizeFunds />
    </div>);
}

export default GeneralInfo;