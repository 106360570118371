import { FunctionComponent } from "react";

import classes from './WinningCriteria.module.scss'

import Ball0 from '../../../FinishedRoundsAndHistory/components/RoundInfo/components/WinningNumber/images/0.svg';
import Ball1 from '../../../FinishedRoundsAndHistory/components/RoundInfo/components/WinningNumber/images/1.svg';
import Ball2 from '../../../FinishedRoundsAndHistory/components/RoundInfo/components/WinningNumber/images/2.svg';
import Ball3 from '../../../FinishedRoundsAndHistory/components/RoundInfo/components/WinningNumber/images/3.svg';
import Ball4 from '../../../FinishedRoundsAndHistory/components/RoundInfo/components/WinningNumber/images/4.svg';
import Ball5 from '../../../FinishedRoundsAndHistory/components/RoundInfo/components/WinningNumber/images/5.svg';
import Ball6 from '../../../FinishedRoundsAndHistory/components/RoundInfo/components/WinningNumber/images/6.svg';
import Ball7 from '../../../FinishedRoundsAndHistory/components/RoundInfo/components/WinningNumber/images/7.svg';
import Ball8 from '../../../FinishedRoundsAndHistory/components/RoundInfo/components/WinningNumber/images/8.svg';
import Ball9 from '../../../FinishedRoundsAndHistory/components/RoundInfo/components/WinningNumber/images/9.svg';


import Check from './images/Check.svg';
import Wrong from './images/Wrong.svg';

interface WinningCriteriaProps {

}

const WinningCriteria: FunctionComponent<WinningCriteriaProps> = () => {

    const winningCombination: number[] = [7, 9, 7, 4, 3, 2];

    const BallImages: string[] = [
        Ball0,
        Ball1,
        Ball2,
        Ball3,
        Ball4,
        Ball5,
        Ball6,
        Ball7,
        Ball8,
        Ball9
    ]

    return (<section className={classes.WinningCriteria}>
        <div className={classes.textBlock}>
            <h1>Winning criteria</h1>
            <h2>The digits on your ticket must match the right order to win</h2>
            <p>Here’s an example lottery draw, with two tickets, A and B</p>
            <ul>
                <li>
                    <span>
                    Ticket A: The first 3 digits and the last 2 digits match, but the 4th digit
                    is wrong, so this ticket only wins a “Match first 3” prize.
                </span>
                </li>

                <li>
                    Ticket B: Even though the last 5 digits match, the first digit is wrong,
                    so this ticket doesn’t win a prize.
                </li>
            </ul>
            <p>
                Prize brackets don’t ‘stack’: if you match the first 3 digits in order, you’ll
                only win prizes from the ‘Match 3’ bracket, and not from ‘Match 1’ and ‘Match 2’.
            </p>
        </div>

        <div className={classes.exampleCriteria}>
            <div className={classes.row}>
                <div className={classes.headerSection}>

                </div>

                <div className={classes.contentSection}>
                    <div className={classes.balls}>
                        {winningCombination.map(number => <img src={BallImages[number]} />)}

                    </div>
                </div>

            </div>
            <div className={classes.row}>
                <div className={classes.headerSection}>
                    A
                </div>

                <div className={classes.contentSection}>
                    <div className={classes.ticketCombo}>
                        <div className={classes.successfulCombo}>
                            <div className={classes.number}>7<img src={Check} /></div>
                            <div className={classes.number}>9<img src={Check} /></div>
                            <div className={classes.number}>7<img src={Check} /></div>
                        </div>
                        <div className={classes.failedCombo}>
                            <div className={classes.number}>1<img src={Wrong} /></div>
                            <div className={classes.number}>3<img src={Check} /></div>
                            <div className={classes.number}>2<img src={Check} /></div>
                        </div>

                    </div>
                </div>
            </div>

            <div className={classes.row}>
                <div className={classes.headerSection}>
                    B
                </div>

                <div className={classes.contentSection}>
                    <div className={classes.completelyFailedCombo}>
                        <div className={classes.number}>8<img src={Wrong} /></div>
                        <div className={classes.number}>9<img src={Check} /></div>
                        <div className={classes.number}>7<img src={Check} /></div>
                        <div className={classes.number}>7<img src={Check} /></div>
                        <div className={classes.number}>9<img src={Check} /></div>
                        <div className={classes.number}>7<img src={Check} /></div>
                    </div>
                </div>
            </div>
        </div>


    </section>);
}

export default WinningCriteria;