import axios from 'axios'

export interface IRefereesRequestObject {
    referrals: string[],
    dateFrom?: number,
    dateTo?: number,
    comissionFrom: number,
    comissionTo: number
    page: number,
    perPage: number
}

export const getReferees = (referrerAddress: string, params: IRefereesRequestObject) => {
    return axios.get('https://api.peakdefi.com/' + 'lottery/referral-purchased-tickets/' + referrerAddress + '?' +
        'page=' + params.page + '&per-page' + params.perPage +
        (params.referrals.length > 0 ? '&referrals=' + params.referrals?.join() : '') +
        (!!params.dateFrom || !!params.dateTo ? '&timestamp=range:' + (params.dateFrom ?? 0) + ',' + (params.dateTo ?? Math.floor(Date.now() / 1000)) : '') +
        (params.comissionFrom>0 || params?.comissionTo>0 ? '&token-rewards=range:' + (params.comissionFrom ?? 0) + ',' + (params.comissionTo ?? 100) : '')
    );
}