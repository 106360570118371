import { FunctionComponent, useState } from "react";
import { useSelector } from "react-redux";
import DetailsSection from "./components/DetailsSection/DetailsSection";
import RoundControls from "./components/RoundControls/RoundControls";
import WinningNumber from "./components/WinningNumber/WinningNumber";
import { setSelectedLottery } from '../../../../../../../features/selectedLotterySlice';
import { setUserSelectIndex } from '../../../../../../../features/lotteryInfoSlice';

import Arrow from './images/Arrow.svg';

import classes from './RoundInfo.module.scss';
import { useDispatch } from 'react-redux';
import { BigNumber } from 'ethers';

interface RoundInfoProps {

}

interface IRoundData {
    drawDate: Date,
    winningNumber: Array<number>
}

const RoundInfo: FunctionComponent<RoundInfoProps> = () => {
    const dispatch = useDispatch();

    const selectedLottery = useSelector((state: any) => state.selectedLotterySlice.selectedLottery)
    const currentRound = useSelector((state: any) => state.lotteryInfo.userSelectIndex)
    const lotteryContract = useSelector((state: any) => state.contracts.lotteryContract)

    const [isSectionCollapsed, setIsSectionCollapsed] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const setCurrentRound = async (lotteryIndex: number) => {
        setIsLoading(true);
        const lotteryInformationHistory = await lotteryContract.viewLottery(lotteryIndex);
        try{
            dispatch(setSelectedLottery({
                amountCollectedInPeak: lotteryInformationHistory.amountCollectedInPeak / Math.pow(10, 8),
                backetNumber: lotteryInformationHistory.backetNumber,
                backetPrice: lotteryInformationHistory.backetPrice.map((price: BigNumber) => { return price.toNumber() }),
                countWinnersPerBracket: lotteryInformationHistory.countWinnersPerBracket.map((price: BigNumber) => { return price.toNumber() }),
                endTime: lotteryInformationHistory.endTime.toNumber(),
                finalNumber: lotteryInformationHistory.finalNumber,
                peakPerBracket: lotteryInformationHistory.peakPerBracket.map((price: number) => { return price / Math.pow(10, 8) }),
                priceTicketInPeak: lotteryInformationHistory.priceTicketInCake, //TODO Change to Peak
                rewardsBreakdown: lotteryInformationHistory.rewardsBreakdown.map((price: BigNumber) => { return price.toNumber() / 100 }),
                startTime: lotteryInformationHistory.startTime.toNumber(),
                status: lotteryInformationHistory.status,
                treasuryFee: 0.05
            }))
            
        } catch (error: any) {
            console.log("error", error)
            setIsLoading(false);
        }
        
        dispatch(setUserSelectIndex(lotteryIndex))
        setIsLoading(false);
    }

    return (<div className={classes.RoundInfo}>
        <div className={classes.roundControls}>
            <RoundControls
                currentRound={currentRound}
                drawDate={new Date(selectedLottery.endTime * 1000)}
                setCurrentRound={setCurrentRound}
            />
        </div>
        <hr />
        <div className={classes.winningNumber}>
            <WinningNumber winningNumber={selectedLottery.finalNumber.toString().split("").reverse()} isLoading={isLoading} />
        </div>
        <hr />

        <div className={classes.collapsibleSectionWrapper} style={{ maxHeight: isSectionCollapsed ? '0' : '100vh' }}>
            <DetailsSection isLoading={isLoading}/>
        </div>

        <div className={classes.collapseControls}>
            <div onClick={() => setIsSectionCollapsed(!isSectionCollapsed)}>Details <img src={Arrow} style={{ transform: isSectionCollapsed ? 'none' : 'rotate(180deg)' }} alt='' /></div>
        </div>
    </div>);
}

export default RoundInfo;