import { FunctionComponent, ReactNode } from "react";

import classes from './ButtonLight.module.scss';

interface ButtonLightProps {
    onClick?: () =>boolean,
    children: ReactNode
}
 
const ButtonLight: FunctionComponent<ButtonLightProps> = ({onClick, children }) => {
    return (<button onClick={onClick} className={classes.ButtonLight}>
        {children}
    </button>);
}
 
export default ButtonLight;