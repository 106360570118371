import { match } from "assert";
import { FunctionComponent } from "react";

import ChartPie from './images/ChartPie.svg'

import classes from './PrizeFunds.module.scss';

interface PrizeFundsProps {

}

interface IPrizePool{
    text: string,
    poolSize: number
}

const PrizeFunds: FunctionComponent<PrizeFundsProps> = () => {
    const matches: IPrizePool[]=[
        {text: 'First 1 digits', poolSize: 3},
        {text: 'First 2 digits', poolSize: 4},
        {text: 'First 3 digits', poolSize: 8},
        {text: 'First 4 digits', poolSize: 15},
        {text: 'First 5 digits', poolSize: 25},
        {text: 'First 6 digits', poolSize: 40},
        {text: 'Burn', poolSize: 5},
    ]

    return (<section className={classes.PrizeFunds}>
        <div className={classes.poolInfo}>
            <img src={ChartPie} alt="pie chart" className={classes.pieChart}/> {/* THIS SHOULD BE REPLACED WITH AN ACTUAL CHART */}

            <div className={classes.matchAndPool}>
                <div className={classes.header}>
                    <h1>Digits matched</h1>
                    <h1>Prize pool</h1>
                </div>

                {
                    matches.map(match=><div className={classes.infoRow}>
                        <div className={classes.matchText}>{match.text}</div>
                        <div className={classes.dots}></div>
                        <div className={classes.poolSize}>{match.poolSize}%</div>
                    </div>)
                }
            </div>
        </div>

        <div className={classes.textSection}>
            <h1>Prize funds</h1>
            <p>The prizes for each lottery round come from three sources:</p>

            <h2>Ticket Purchases</h2>
            <ul>
                <li>
                    95% of the PEAK paid by people buying tickets that round goes back into
                    the prize pools.
                </li>
            </ul>

            <h2>Rollover Prizes</h2>
            <ul>
                <li>
                    After every round, if nobody wins in one of the prize brackets, the unclaimed
                    PEAK for that bracket rolls over into the next round and are redistributed
                    among the prize pools.
                </li>
            </ul>
        </div>

    </section>);
}

export default PrizeFunds;